import { createTheme as createMuiTheme } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';
import { COLORS, SPACING, TYPOGRAPHY, SHAPE, TRANSITIONS } from './constants';

type ThemeMode = 'light' | 'dark';

declare module '@mui/material/styles' {
  interface Theme {
    shape: {
      borderRadius: {
        pill: number;
        bubble: number;
        card: number;
        small: number;
      };
    };
  }
  
  interface ThemeOptions {
    shape?: {
      borderRadius?: {
        pill?: number;
        bubble?: number;
        card?: number;
        small?: number;
      };
    };
  }
}

export const createTheme = (mode: ThemeMode): Theme => {
  return createMuiTheme({
    palette: {
      mode,
      primary: {
        main: '#CF13B3',
      },
      text: mode === 'light' ? COLORS.text.light : COLORS.text.dark,
      background: mode === 'light' ? COLORS.background.light : COLORS.background.dark,
      action: COLORS.action,
      gradient: COLORS.gradient,
    },
    typography: {
      fontFamily: TYPOGRAPHY.fontFamily,
      fontSize: TYPOGRAPHY.fontSize,
      fontWeightLight: TYPOGRAPHY.fontWeightLight,
      fontWeightRegular: TYPOGRAPHY.fontWeightRegular,
      fontWeightMedium: TYPOGRAPHY.fontWeightMedium,
      fontWeightBold: TYPOGRAPHY.fontWeightBold,
      h1: {
        fontSize: `${TYPOGRAPHY.fontSize * TYPOGRAPHY.scale.h1}px`,
        fontWeight: TYPOGRAPHY.fontWeightBold,
      },
      h2: {
        fontSize: `${TYPOGRAPHY.fontSize * TYPOGRAPHY.scale.h2}px`,
        fontWeight: TYPOGRAPHY.fontWeightBold,
      },
      h3: {
        fontSize: `${TYPOGRAPHY.fontSize * TYPOGRAPHY.scale.h3}px`,
        fontWeight: TYPOGRAPHY.fontWeightMedium,
      },
      body1: {
        fontSize: `${TYPOGRAPHY.fontSize * TYPOGRAPHY.scale.body1}px`,
      },
      body2: {
        fontSize: `${TYPOGRAPHY.fontSize * TYPOGRAPHY.scale.body2}px`,
      },
    },
    spacing: SPACING.base,
    shape: {
      borderRadius: SHAPE.borderRadius,
    },
    transitions: TRANSITIONS,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          'html, body': {
            overflow: 'hidden',
            scrollbarWidth: 'none',  // Firefox
            '&::-webkit-scrollbar': {
              display: 'none',  // Chrome, Safari, Edge
            },
            '-ms-overflow-style': 'none',  // IE
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: SPACING.base,
            width: 40,
            height: 40,
            color: mode === 'light' ? COLORS.text.light.secondary : COLORS.text.dark.secondary,
            '&:hover': {
              color: COLORS.gradient.center,
            },
          },
        },
      },
    },
  });
};

export * from './types'; 