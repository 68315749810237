import { initializeApp, FirebaseApp } from 'firebase/app';
import { 
  getFirestore, 
  collection, 
  query, 
  orderBy, 
  getDocs, 
  doc, 
  setDoc, 
  deleteDoc, 
  serverTimestamp, 
  updateDoc,
  Firestore,
  getDoc,
  limit
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import type { Message, MessageWithId, Conversation } from '../types';

const isDevelopment = process.env.NODE_ENV === 'development';
let isDevelopmentMode = false;

interface FirebaseConfig {
  apiKey: string | undefined;
  authDomain: string | undefined;
  projectId: string | undefined;
  storageBucket: string | undefined;
  messagingSenderId: string | undefined;
  appId: string | undefined;
  [key: string]: string | undefined;
}

const firebaseConfig: FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Validate Firebase config
const validateConfig = () => {
  const requiredFields = ['apiKey', 'authDomain', 'projectId', 'storageBucket', 'messagingSenderId', 'appId'];
  const missingFields = requiredFields.filter(field => !firebaseConfig[field]);
  
  if (missingFields.length > 0) {
    throw new Error(`Missing required Firebase configuration: ${missingFields.join(', ')}`);
  }

  if (isDevelopment) {
    console.log('Firebase Config Status:', {
      apiKeyPresent: !!firebaseConfig.apiKey,
      authDomainPresent: !!firebaseConfig.authDomain,
      projectIdPresent: !!firebaseConfig.projectId,
      configValid: missingFields.length === 0
    });
  }
};

// Initialize Firebase
let app: FirebaseApp;
let db: Firestore;

export const setDevelopmentMode = (enabled: boolean) => {
  isDevelopmentMode = enabled;
  if (isDevelopment) {
    console.log('Development mode set to:', enabled);
  }
};

const initializeFirebase = () => {
  if (!app) {
    try {
      validateConfig();
      app = initializeApp(firebaseConfig);
      db = getFirestore(app);
      if (isDevelopment) {
        console.log('Firebase initialized successfully');
      }
    } catch (error) {
      if (isDevelopment) {
        console.error('Firebase initialization error:', error);
      }
      throw error;
    }
  }
  return { app, db };
};

// Ensure Firebase is initialized
initializeFirebase();

// Session storage key for temporary conversations
const SESSION_CONVERSATIONS_KEY = 'temp_conversations';

const getCurrentUserId = (): string | null => {
  try {
    // If in development mode, return the development user ID
    if (isDevelopmentMode) {
      if (isDevelopment) {
        console.log('getCurrentUserId - Using development user ID');
      }
      return 'development-user';
    }

    const auth = getAuth(app);
    const user = auth.currentUser;
    if (isDevelopment) {
      console.log('getCurrentUserId - Current Firebase Auth User:', {
        uid: user?.uid,
        email: user?.email,
        isDev: user?.uid === 'development-user'
      });
    }
    return user?.uid || null;
  } catch (error) {
    if (isDevelopment) {
      console.error('Error getting current user:', error);
    }
    return null;
  }
};

const getUserConversationsRef = () => {
  const { db } = initializeFirebase();
  
  const userId = getCurrentUserId();
  if (!userId) {
    if (isDevelopment) {
      console.error('No authenticated user found in getUserConversationsRef');
    }
    throw new Error('No authenticated user found');
  }
  
  const ref = collection(db, 'users', userId, 'conversations');
  if (isDevelopment) {
    console.log('Getting conversations from path:', `users/${userId}/conversations`);
  }
  return ref;
};

// Get conversations from session storage
const getSessionConversations = (): Conversation[] => {
  try {
    const stored = sessionStorage.getItem(SESSION_CONVERSATIONS_KEY);
    if (!stored) return [];
    const parsed = JSON.parse(stored);
    return Array.isArray(parsed) ? parsed : [];
  } catch (error) {
    if (isDevelopment) {
      console.error('Error reading from session storage:', error);
    }
    return [];
  }
};

// Save conversations to session storage
const saveSessionConversations = (conversations: Conversation[]) => {
  try {
    sessionStorage.setItem(SESSION_CONVERSATIONS_KEY, JSON.stringify(conversations));
  } catch (error) {
    if (isDevelopment) {
      console.error('Error saving to session storage:', error);
    }
  }
};

export const saveConversation = async (messages: Message[], conversationId: string | null = null): Promise<string> => {
  const userId = getCurrentUserId();
  
  // Add timestamps to messages
  const messagesWithTimestamp: MessageWithId[] = messages.map(msg => ({
    ...msg,
    timestamp: new Date()
  }));
  
  // If no user is authenticated, save to session storage
  if (!userId) {
    const conversations = getSessionConversations();
    const firstUserMessage = messages.find(msg => msg.role === 'user');
    const defaultTitle = firstUserMessage 
      ? firstUserMessage.content.slice(0, 30) + (firstUserMessage.content.length > 30 ? '...' : '')
      : 'New Conversation';

    if (!conversationId) {
      // Create new conversation
      const newConversation: Conversation = {
        id: `temp_${Date.now()}`,
        title: defaultTitle,
        messages: messagesWithTimestamp,
        timestamp: new Date(),
        lastUpdated: new Date()
      };
      conversations.push(newConversation);
      saveSessionConversations(conversations);
      return newConversation.id;
    } else {
      // Update existing conversation
      const conversation = conversations.find(c => c.id === conversationId);
      if (!conversation) throw new Error('Conversation not found');
      conversation.messages = messagesWithTimestamp;
      conversation.lastUpdated = new Date();
      saveSessionConversations(conversations);
      return conversationId;
    }
  }

  // If user is authenticated, save to Firestore
  try {
    const conversationsRef = getUserConversationsRef();
    
    if (!conversationId) {
      // Create new conversation
      const conversationRef = doc(conversationsRef);
      const firstUserMessage = messages.find(msg => msg.role === 'user');
      const defaultTitle = firstUserMessage 
        ? firstUserMessage.content.slice(0, 30) + (firstUserMessage.content.length > 30 ? '...' : '')
        : 'New Conversation';
      
      console.log('[Firebase] Creating new conversation', {
        title: defaultTitle,
        messageCount: messages.length
      });
      
      await setDoc(conversationRef, {
        title: defaultTitle,
        timestamp: serverTimestamp(),
        messages: messagesWithTimestamp,
        lastUpdated: serverTimestamp()
      });
      
      return conversationRef.id;
    } else {
      // Update existing conversation
      const conversationRef = doc(conversationsRef, conversationId);
      
      console.log('[Firebase] Updating conversation', {
        conversationId,
        messageCount: messages.length
      });
      
      await updateDoc(conversationRef, {
        messages: messagesWithTimestamp,
        lastUpdated: serverTimestamp()
      });
      
      return conversationId;
    }
  } catch (error) {
    console.error('[Firebase] Error saving conversation:', error);
    throw error;
  }
};

export const deleteConversation = async (conversationId: string): Promise<void> => {
  const userId = getCurrentUserId();
  
  // If no user is authenticated, delete from session storage
  if (!userId) {
    const conversations = getSessionConversations();
    const updatedConversations = conversations.filter(c => c.id !== conversationId);
    saveSessionConversations(updatedConversations);
    return;
  }

  // If user is authenticated, delete from Firestore
  try {
    await deleteDoc(doc(getUserConversationsRef(), conversationId));
  } catch (error) {
    if (isDevelopment) {
      console.error('Error deleting conversation:', error);
    }
    throw error;
  }
};

export const getConversations = async (): Promise<Conversation[]> => {
  const userId = getCurrentUserId();
  
  // If no user is authenticated or storage access fails, get from session storage
  if (!userId) {
    return getSessionConversations();
  }

  // If user is authenticated, try Firestore first, fall back to session storage
  try {
    const conversationsRef = getUserConversationsRef();
    const q = query(conversationsRef, orderBy('timestamp', 'desc'));
    
    const snapshot = await getDocs(q);
    
    const conversations = snapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        title: data.title || 'Untitled',
        messages: data.messages || [],
        timestamp: data.timestamp?.toDate() || new Date(),
        lastUpdated: data.lastUpdated?.toDate() || new Date()
      };
    });
    
    console.log('[Firebase] Loaded conversations', {
      count: conversations.length
    });
    
    return conversations;
  } catch (error) {
    console.error('[Firebase] Error getting conversations:', error);
    return getSessionConversations();
  }
}; 