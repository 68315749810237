import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { styled } from '@mui/material/styles';
import { ICON_SIZES } from '../../../shared/theme/constants';

export type IconSize = 'small' | 'medium' | 'large';

export interface IconProps {
  icon: IconProp;
  size?: IconSize;
  color?: string;
  className?: string;
}

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<{ $size: IconSize; $color?: string }>(
  ({ theme, $size, $color }) => ({
    width: `${ICON_SIZES[$size]}px !important`,
    height: `${ICON_SIZES[$size]}px !important`,
    color: $color || 'inherit',
  })
);

export const Icon: React.FC<IconProps> = ({ 
  icon, 
  size = 'medium', 
  color,
  className 
}) => {
  return (
    <StyledFontAwesomeIcon
      icon={icon}
      $size={size}
      $color={color}
      className={className}
    />
  );
};

export default Icon; 