import OpenAI from 'openai';
import type { ChatCompletionMessageParam } from 'openai/resources/chat/completions';
import type { Stream } from 'openai/streaming';
import type { CustomInstruction } from '../types/instructions.types';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  project: process.env.REACT_APP_OPENAI_PROJECT_ID,
  dangerouslyAllowBrowser: true
});

const DEFAULT_SYSTEM_PROMPT = "You are a helpful AI assistant.";

type MessageRole = "system" | "user" | "assistant";
interface Message {
  role: MessageRole;
  content: string;
}

export const streamCompletion = async function* (
  message: string,
  previousMessages: Message[] = [],
  signal?: AbortSignal,
  customInstruction: CustomInstruction | null = null
) {
  let stream: Stream<OpenAI.Chat.Completions.ChatCompletionChunk> | undefined;
  try {
    const systemMessage: ChatCompletionMessageParam = {
      role: "system",
      content: customInstruction?.content || DEFAULT_SYSTEM_PROMPT
    };

    const messages: ChatCompletionMessageParam[] = [
      systemMessage,
      ...previousMessages.map(msg => ({
        role: msg.role,
        content: msg.content
      })),
      { role: "user", content: message }
    ];

    stream = await openai.chat.completions.create({
      model: "gpt-4",
      messages,
      stream: true,
    }, { signal });

    for await (const chunk of stream) {
      if (signal?.aborted) {
        break;
      }
      const content = chunk.choices[0]?.delta?.content || '';
      if (content) {
        yield content;
      }
    }
  } catch (err) {
    const error = err as Error;
    if (error.name === 'AbortError') {
      await cleanupStream(stream);
      return;
    }
    
    if (error instanceof Error) {
      const apiError = error as any;
      const errorMessage = apiError.response?.data?.error?.message || error.message;
      throw new Error(errorMessage);
    }
    
    throw new Error('An error occurred with the OpenAI API');
  } finally {
    if (signal?.aborted) {
      await cleanupStream(stream);
    }
  }
};

async function cleanupStream(
  stream?: Stream<OpenAI.Chat.Completions.ChatCompletionChunk>
) {
  if (stream?.controller) {
    try {
      await stream.controller.abort();
    } catch (cleanupError) {
      return;
    }
  }
} 