import { collection, addDoc, updateDoc, deleteDoc, doc, Timestamp, query, orderBy } from 'firebase/firestore';
import { db } from '../../config/firebase';
import type { CreateInstructionData } from '../types/instructions.types';

const COLLECTION_NAME = 'customInstructions';

export const createInstruction = async (data: CreateInstructionData, userId: string) => {
  const now = Timestamp.now();
  await addDoc(collection(db, COLLECTION_NAME), {
    ...data,
    createdAt: now,
    updatedAt: now,
    createdBy: userId,
    isPublic: data.isPublic
  });
};

export const updateInstruction = async (id: string, data: CreateInstructionData) => {
  const instructionRef = doc(db, COLLECTION_NAME, id);
  await updateDoc(instructionRef, {
    ...data,
    updatedAt: Timestamp.now()
  });
};

export const deleteInstruction = async (id: string) => {
  await deleteDoc(doc(db, COLLECTION_NAME, id));
};

export const getInstructionsQuery = (userId?: string) => {
  const baseQuery = collection(db, COLLECTION_NAME);
  
  // Instead of using complex OR conditions, we'll fetch all instructions
  // and filter them in the client side. This avoids the need for complex indexes.
  return query(
    baseQuery,
    orderBy('updatedAt', 'desc')
  );
}; 