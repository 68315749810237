import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Alert,
  useTheme,
  Popover,
  colors,
  FormControlLabel,
  Switch,
} from '@mui/material';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useCustomInstructions } from '../../shared/context/CustomInstructionsContext';
import type { CustomInstruction, CreateInstructionData } from '../../shared/types/instructions.types';
import { InstructionCard } from '../common/InstructionCard/InstructionCard';
import { HatIcon } from '../common/HatIcon/HatIcon';

const COLOR_GRID = [
  // Bright colors (300)
  [
    colors.red[300],
    colors.deepOrange[300],
    colors.orange[300],
    colors.amber[300],
    colors.yellow[300],
    colors.lime[300],
    colors.green[300],
    colors.teal[300],
    colors.blue[300],
    colors.indigo[300],
    colors.purple[300],
    colors.deepPurple[300],
  ],
  // Medium colors (500)
  [
    colors.red[500],
    colors.deepOrange[500],
    colors.orange[500],
    colors.amber[500],
    colors.yellow[500],
    colors.lime[500],
    colors.green[500],
    colors.teal[500],
    colors.blue[500],
    colors.indigo[500],
    colors.purple[500],
    colors.deepPurple[500],
  ],
  // Dark colors (700)
  [
    colors.red[700],
    colors.deepOrange[700],
    colors.orange[700],
    colors.amber[700],
    colors.yellow[700],
    colors.lime[700],
    colors.green[700],
    colors.teal[700],
    colors.blue[700],
    colors.indigo[700],
    colors.purple[700],
    colors.deepPurple[700],
  ],
  // Neutral colors
  [
    colors.grey[300],
    colors.grey[400],
    colors.grey[500],
    colors.grey[600],
    colors.grey[700],
    colors.grey[800],
    colors.grey[900],
    colors.blueGrey[500],
    colors.blueGrey[700],
    colors.brown[500],
    colors.brown[700],
    colors.brown[900],
  ],
];

export const InstructionsManager: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { instructions, loading, error, createInstruction, updateInstruction, deleteInstruction } = useCustomInstructions();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedInstruction, setSelectedInstruction] = useState<CustomInstruction | null>(null);
  const [formData, setFormData] = useState({ 
    title: '', 
    content: '', 
    hatColor: theme.palette.primary.main,
    isPublic: false 
  });
  const [isSaving, setIsSaving] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [colorPickerAnchor, setColorPickerAnchor] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setIsDialogOpen(false);
    setSelectedInstruction(null);
    setFormData({ 
      title: '', 
      content: '', 
      hatColor: theme.palette.primary.main,
      isPublic: false 
    });
    setFormError(null);
    setColorPickerAnchor(null);
  };

  const handleAdd = () => {
    setSelectedInstruction(null);
    setFormData({ 
      title: '', 
      content: '', 
      hatColor: theme.palette.primary.main,
      isPublic: false 
    });
    setIsDialogOpen(true);
  };

  const handleEdit = (instruction: CustomInstruction) => {
    setSelectedInstruction(instruction);
    setFormData({
      title: instruction.title,
      content: instruction.content,
      hatColor: instruction.hatColor || theme.palette.primary.main,
      isPublic: instruction.isPublic
    });
    setIsDialogOpen(true);
  };

  const handleColorPickerClick = (event: React.MouseEvent<HTMLElement>) => {
    setColorPickerAnchor(event.currentTarget);
  };

  const handleColorPickerClose = () => {
    setColorPickerAnchor(null);
  };

  const handleColorChange = (color: string) => {
    setFormData(prev => ({ ...prev, hatColor: color }));
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Weet je zeker dat je deze instructie wilt verwijderen?')) {
      try {
        await deleteInstruction(id);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Fout bij het verwijderen van de instructie:', err);
      }
    }
  };

  const handleSave = async () => {
    if (!formData.title.trim() || !formData.content.trim()) {
      setFormError('Titel en inhoud zijn verplicht');
      return;
    }

    try {
      setIsSaving(true);
      setFormError(null);

      const data: CreateInstructionData = {
        title: formData.title.trim(),
        content: formData.content.trim(),
        hatColor: formData.hatColor,
        isPublic: formData.isPublic
      };

      if (selectedInstruction) {
        await updateInstruction(selectedInstruction.id, data);
      } else {
        await createInstruction(data);
      }

      handleClose();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Fout bij het opslaan van de instructie:', err);
      setFormError(err instanceof Error ? err.message : 'Fout bij het opslaan van de instructie');
    } finally {
      setIsSaving(false);
    }
  };

  if (loading) {
    return (
      <SimpleBar style={{ height: '100vh', width: '100%' }}>
        <Box 
          sx={{ 
            padding: 3,
            maxWidth: 800,
            margin: '0 auto',
            marginTop: 8,
            minHeight: 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      </SimpleBar>
    );
  }

  return (
    <SimpleBar style={{ height: '100vh', width: '100%' }}>
      <Box 
        sx={{ 
          padding: 3,
          maxWidth: 800,
          margin: '0 auto',
          marginTop: 8,
          marginBottom: 4
        }}
      >
        <Box 
          sx={{ 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 3
          }}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <IconButton onClick={() => navigate('/chat')} color="inherit">
              <FontAwesomeIcon icon="arrow-left" />
            </IconButton>
            <Typography variant="h5">Aangepaste Instructies</Typography>
          </Box>
          <Button
            variant="contained"
            onClick={handleAdd}
            startIcon={<FontAwesomeIcon icon="plus" />}
          >
            Toevoegen
          </Button>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ paddingBottom: 6 }}>
          {instructions.length === 0 ? (
            <Typography color="textSecondary" align="center" py={4}>
              Nog geen aangepaste instructies. Klik op Toevoegen om er een te maken.
            </Typography>
          ) : (
            instructions.map((instruction) => (
              <InstructionCard
                key={instruction.id}
                instruction={instruction}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            ))
          )}
        </Box>

        <Dialog
          open={isDialogOpen}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              height: '90vh', // Take up most of the viewport height
              display: 'flex',
              flexDirection: 'column'
            }
          }}
        >
          <DialogTitle>
            {selectedInstruction ? 'Instructie Bewerken' : 'Instructie Toevoegen'}
          </DialogTitle>
          <DialogContent sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1, height: '100%' }}>
              {formError && (
                <Alert severity="error" sx={{ mb: 1 }}>
                  {formError}
                </Alert>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box
                  onClick={handleColorPickerClick}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    padding: 1,
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    transition: theme.transitions.create(['border-color', 'box-shadow']),
                    '&:hover': {
                      borderColor: 'primary.main',
                    },
                  }}
                >
                  <HatIcon color={formData.hatColor} />
                  <Typography variant="body2">Hoed Kleur Wijzigen</Typography>
                </Box>
                <TextField
                  label="Titel"
                  value={formData.title}
                  onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                  error={!!formError && !formData.title.trim()}
                  helperText={formError && !formData.title.trim() ? 'Titel is verplicht' : ''}
                  fullWidth
                />
              </Box>
              <TextField
                label="Instructies"
                value={formData.content}
                onChange={(e) => setFormData(prev => ({ ...prev, content: e.target.value }))}
                error={!!formError && !formData.content.trim()}
                helperText={formError && !formData.content.trim() ? 'Instructies zijn verplicht' : ''}
                multiline
                fullWidth
                sx={{
                  flex: 1,
                  '& .MuiInputBase-root': {
                    height: '100%',
                  },
                  '& .MuiInputBase-input': {
                    height: '100% !important',
                    overflow: 'auto !important'
                  }
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ 
            justifyContent: 'space-between', 
            px: 3, 
            py: 2 
          }}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.isPublic}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData(prev => ({ ...prev, isPublic: e.target.checked }))}
                  color="primary"
                />
              }
              label={
                <Box>
                  <Typography variant="body1">Openbare Instructie</Typography>
                  <Typography variant="caption" color="text.secondary">
                    Indien ingeschakeld is deze instructie beschikbaar voor alle gebruikers
                  </Typography>
                </Box>
              }
            />
            <Box>
              <Button onClick={handleClose} disabled={isSaving}>
                Annuleren
              </Button>
              <Button onClick={handleSave} variant="contained" disabled={isSaving} sx={{ ml: 1 }}>
                {isSaving ? 'Opslaan...' : 'Opslaan'}
              </Button>
            </Box>
          </DialogActions>

          <Popover
            open={Boolean(colorPickerAnchor)}
            anchorEl={colorPickerAnchor}
            onClose={handleColorPickerClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{
              '& .MuiPopover-paper': {
                p: 2,
                boxShadow: theme.shadows[8],
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                p: 1,
              }}
            >
              {COLOR_GRID.map((row, rowIndex) => (
                <Box
                  key={rowIndex}
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  {row.map((color) => (
                    <Box
                      key={color}
                      onClick={() => {
                        handleColorChange(color);
                        handleColorPickerClose();
                      }}
                      sx={{
                        width: 28,
                        height: 28,
                        backgroundColor: color,
                        borderRadius: '50%',
                        cursor: 'pointer',
                        border: formData.hatColor === color ? '2px solid' : '2px solid transparent',
                        borderColor: formData.hatColor === color ? 'primary.main' : 'transparent',
                        transition: theme.transitions.create(['transform', 'border-color', 'box-shadow'], {
                          duration: 0.2,
                        }),
                        '&:hover': {
                          transform: 'scale(1.2)',
                          zIndex: 1,
                          boxShadow: '0 0 8px rgba(0,0,0,0.2)',
                        },
                      }}
                    />
                  ))}
                </Box>
              ))}
            </Box>
          </Popover>
        </Dialog>
      </Box>
    </SimpleBar>
  );
}; 