import { ThemeColors, ThemeSpacing, ThemeTypography, ThemeShape, ThemeTransitions, IconSizes } from './types';

export const COLORS: ThemeColors = {
  gradient: {
    topRight: '#E6196B',
    center: '#CF13B3',
    bottomLeft: '#8D0BD1',
    primary: 'linear-gradient(45deg, #8D0BD1, #CF13B3, #E6196B)',
    hover: 'linear-gradient(45deg, #7A0AB8, #B811A0, #D1175F)',
    transparent: 'linear-gradient(45deg, rgba(141,11,209,0.9), rgba(207,19,179,0.9), rgba(230,25,107,0.9))'
  },
  text: {
    light: {
      primary: '#616166',
      secondary: '#A6A6A8',
      disabled: 'rgba(97,97,102,0.38)'
    },
    dark: {
      primary: '#F5F5F7',
      secondary: '#A6A6A8',
      disabled: 'rgba(245,245,247,0.38)'
    }
  },
  background: {
    light: {
      default: '#FFFFFF',
      paper: '#F5F5F7',
      hover: '#EAEAEC',
      dark: '#141422'
    },
    dark: {
      default: '#070614',
      paper: '#141422',
      hover: '#1C1C2E',
      dark: '#000000'
    }
  },
  action: {
    active: '#CF13B3',
    hover: 'rgba(207,19,179,0.04)',
    selected: 'rgba(207,19,179,0.08)',
    disabled: 'rgba(207,19,179,0.26)',
    focus: 'rgba(207,19,179,0.12)'
  }
} as const;

export const SPACING: ThemeSpacing = {
  base: 8,
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 48,
  iconPadding: {
    small: 8,
    medium: 16
  },
  contentPadding: {
    primary: 32,
    emphasis: 64
  }
} as const;

export const TYPOGRAPHY: ThemeTypography = {
  htmlFontSize: 14,
  fontFamily: 'Montserrat, sans-serif',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  scale: {
    h1: 3.5,
    h2: 2.2,
    h3: 1.5,
    body1: 1,
    body2: 0.875
  }
} as const;

export const SHAPE: ThemeShape = {
  borderRadius: {
    pill: 24,
    bubble: 16,
    card: 8,
    small: 4
  },
  shadows: {
    sm: '0 2px 4px rgba(0,0,0,0.1)',
    md: '0 4px 8px rgba(0,0,0,0.12)',
    lg: '0 8px 16px rgba(0,0,0,0.14)'
  }
} as const;

export const TRANSITIONS: ThemeTransitions = {
  duration: {
    shortest: 150,
    shorter: 200,
    short: 250,
    standard: 300,
    complex: 375,
    enteringScreen: 225,
    leavingScreen: 195
  },
  easing: {
    easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
    easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
    easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
    sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
  }
} as const;

export const ICON_SIZES: IconSizes = {
  small: 16,    // status indicators
  medium: 24,   // buttons, actions
  large: 32     // section headers
} as const; 