import React, { useState, useMemo } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, useMediaQuery, useTheme, Box, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ImageIcon from '@mui/icons-material/Image';
import { Button } from '../../common';
import { TOP_BAR_HEIGHT } from '../../../shared/constants';
import { useAuth } from '../../../shared/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { generateImage } from '../../../shared/services/imageGenerationService';
import { ImageModal } from '../../common/ImageModal/ImageModal';
import CircularProgress from '@mui/material/CircularProgress';

// Shadow elevation level for consistent UI elevation
const CUSTOM_SHADOW = '0px 2px 12px rgba(0, 0, 0, 0.08)';
const CUSTOM_HOVER_SHADOW = '0px 3px 16px rgba(0, 0, 0, 0.12)';

export interface TopBarProps {
  onNewChat: () => void;
  onToggleSidebar: () => void;
  title: string;
  sidebarOpen?: boolean;
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: TOP_BAR_HEIGHT,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: CUSTOM_SHADOW,
  borderBottom: `1px solid ${theme.palette.divider}`,
  backdropFilter: 'blur(8px)',
  zIndex: theme.zIndex.drawer + 1,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  height: TOP_BAR_HEIGHT,
  padding: theme.spacing(0, 3),
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(2)
}));

const Title = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  marginLeft: theme.spacing(3),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: -30,
  transform: 'translateX(-50%)',
  minWidth: 'unset',
  height: 40,
  width: 40,
  borderRadius: '50%',
  padding: theme.spacing(1),
  backgroundColor: '#FFFFFF',
  color: theme.palette.text.secondary,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: CUSTOM_SHADOW,
  zIndex: theme.zIndex.drawer + 2,
  '&:hover': {
    backgroundColor: '#FFFFFF',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: CUSTOM_HOVER_SHADOW,
  },
  '& .MuiButton-startIcon': {
    margin: 0,
  },
  '& svg': {
    fontSize: '1rem',
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 32,
  height: 32,
  fontSize: '1rem',
  backgroundColor: theme.palette.text.secondary,
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem',
  }
}));

const DevModeIndicator = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: -2,
  right: -2,
  width: 14,
  height: 14,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  border: `2px solid ${theme.palette.background.paper}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    fontSize: '0.5rem',
    color: theme.palette.common.white
  }
}));

export const TopBar: React.FC<TopBarProps> = ({
  onNewChat,
  onToggleSidebar,
  title,
  sidebarOpen
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [generatedImageUrl, setGeneratedImageUrl] = useState<string | null>(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const theme = useTheme();
  const isVerySmall = useMediaQuery('(max-width:400px)');
  const isSmall = useMediaQuery('(max-width:600px)');
  const isMedium = useMediaQuery('(max-width:800px)');
  
  const { user, isDevelopmentMode, toggleDevelopmentMode, signInWithGoogle, logout, canEnableDevelopmentMode } = useAuth();
  const navigate = useNavigate();

  const newChatButtonState = useMemo(() => {
    if (isVerySmall) return 'overflow';
    if (isSmall) return 'compact';
    return 'full';
  }, [isVerySmall, isSmall]);

  const overflowItems = useMemo(() => {
    const items = [];
    if (newChatButtonState === 'overflow') items.push('newChat');
    if (isSmall) items.push('chatTitle');
    if (isMedium) {
      items.push('devMode');
      items.push('auth');
    }
    return items;
  }, [newChatButtonState, isSmall, isMedium]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action: string) => {
    switch (action) {
      case 'newChat':
        onNewChat();
        break;
      case 'devMode':
        toggleDevelopmentMode();
        break;
      case 'signIn':
        signInWithGoogle();
        break;
      case 'signOut':
        logout();
        break;
    }
    handleMenuClose();
  };

  const handleGenerateImage = async () => {
    setIsGeneratingImage(true);
    try {
      const imageUrl = await generateImage("Create a friendly, expressive smiley face emoji in a modern, digital art style");
      setGeneratedImageUrl(imageUrl);
      setImageModalOpen(true);
    } catch (error) {
      console.error('Image generation failed:', error);
      // You might want to add a proper error handling UI here
    } finally {
      setIsGeneratingImage(false);
    }
  };

  const renderAuthButton = () => {
    if (!overflowItems.includes('auth')) {
      return (
        <IconButton
          color="inherit"
          onClick={user ? () => handleMenuItemClick('signOut') : () => handleMenuItemClick('signIn')}
          sx={{ 
            padding: theme.spacing(1),
            color: theme.palette.text.secondary,
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }}
        >
          {user ? <LogoutIcon /> : <LoginIcon />}
        </IconButton>
      );
    }
    return null;
  };

  const renderDevModeButton = () => {
    if (!canEnableDevelopmentMode || overflowItems.includes('devMode')) {
      return null;
    }

    return (
      <IconButton
        color="inherit"
        onClick={() => handleMenuItemClick('devMode')}
        sx={{
          padding: theme.spacing(1),
          color: isDevelopmentMode ? theme.palette.success.main : theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.primary.main,
          },
        }}
      >
        <FontAwesomeIcon icon="bug" />
      </IconButton>
    );
  };

  const renderCustomInstructionsButton = () => {
    if (!isDevelopmentMode || overflowItems.includes('devMode')) {
      return null;
    }

    return (
      <>
        <IconButton
          color="inherit"
          onClick={() => navigate('/instructions')}
          sx={{
            padding: theme.spacing(1),
            color: theme.palette.text.secondary,
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }}
        >
          <FontAwesomeIcon icon="robot" />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={handleGenerateImage}
          disabled={isGeneratingImage}
          sx={{
            padding: theme.spacing(1),
            color: theme.palette.text.secondary,
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }}
        >
          {isGeneratingImage ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <ImageIcon />
          )}
        </IconButton>
      </>
    );
  };

  const renderUserAvatar = () => {
    if (user?.photoURL) {
      return (
        <Box position="relative">
          <StyledAvatar 
            src={user.photoURL}
            alt={user.displayName || 'User avatar'}
          />
          {isDevelopmentMode && (
            <DevModeIndicator>
              <FontAwesomeIcon icon="bug" />
            </DevModeIndicator>
          )}
        </Box>
      );
    }

    return (
      <Box position="relative">
        <StyledAvatar>
          <PersonIcon />
        </StyledAvatar>
        {isDevelopmentMode && (
          <DevModeIndicator>
            <FontAwesomeIcon icon="bug" />
          </DevModeIndicator>
        )}
      </Box>
    );
  };

  return (
    <>
      <StyledAppBar position="fixed">
        <StyledToolbar>
          <Box display="flex" alignItems="center" position="relative" sx={{ width: 240 }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="toggle menu"
              onClick={onToggleSidebar}
              sx={{ 
                padding: theme.spacing(1)
              }}
            >
              <MenuIcon />
            </IconButton>

            {newChatButtonState !== 'overflow' && (
              <StyledButton
                onClick={onNewChat}
                variant="text"
                aria-label="Nieuwe conversatie"
              >
                <FontAwesomeIcon icon="plus" />
              </StyledButton>
            )}
          </Box>

          <Box flexGrow={1} display="flex" alignItems="center">
            {!overflowItems.includes('chatTitle') && (
              <Title variant="h6">
                {title.length > 15 ? `${title.slice(0, 15)}...` : title}
              </Title>
            )}
          </Box>

          <Box display="flex" alignItems="center" ml="auto" gap={1}>
            {renderDevModeButton()}
            {renderCustomInstructionsButton()}
            {renderAuthButton()}
            {renderUserAvatar()}

            {overflowItems.length > 0 && (
              <>
                <IconButton
                  color="inherit"
                  aria-label="more"
                  onClick={handleMenuOpen}
                  sx={{ 
                    padding: theme.spacing(1)
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  {overflowItems.includes('newChat') && (
                    <MenuItem onClick={() => handleMenuItemClick('newChat')}>
                      Nieuwe conversatie
                    </MenuItem>
                  )}
                  {overflowItems.includes('chatTitle') && (
                    <MenuItem onClick={handleMenuClose}>
                      {title}
                    </MenuItem>
                  )}
                  {overflowItems.includes('devMode') && canEnableDevelopmentMode && (
                    <MenuItem 
                      onClick={() => handleMenuItemClick('devMode')}
                      sx={{ 
                        color: isDevelopmentMode ? theme.palette.success.main : 'inherit'
                      }}
                    >
                      <FontAwesomeIcon icon="bug" style={{ marginRight: theme.spacing(1) }} />
                      {isDevelopmentMode ? 'Disable Development Mode' : 'Enable Development Mode'}
                    </MenuItem>
                  )}
                  {overflowItems.includes('auth') && (
                    <MenuItem onClick={() => handleMenuItemClick(user ? 'signOut' : 'signIn')}>
                      {user ? (
                        <>
                          <LogoutIcon sx={{ mr: 1 }} />
                          Sign Out
                        </>
                      ) : (
                        <>
                          <LoginIcon sx={{ mr: 1 }} />
                          Sign In with Google
                        </>
                      )}
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
          </Box>
        </StyledToolbar>
      </StyledAppBar>
      <ImageModal
        open={imageModalOpen}
        onClose={() => setImageModalOpen(false)}
        imageUrl={generatedImageUrl}
      />
    </>
  );
}; 