import { ImageGenerationOptions } from '../services/imageGenerationService';

interface ParsedImageCommand {
  prompt: string;
  options: ImageGenerationOptions;
}

export const parseImageGenerationCommand = (message: string): ParsedImageCommand | null => {
  // Support both inline and standalone gen commands
  const genRegex = /~gen\s+(.*?)(?:\s+(\d+:\d+))?\s*(vivid|natural)?\s*~/i;
  const match = message.match(genRegex);

  if (!match) return null;

  const [, prompt, ratio, style] = match;
  const options: ImageGenerationOptions = {
    style: (style?.toLowerCase() as 'vivid' | 'natural') || 'vivid',
    quality: 'hd'
  };

  // Map aspect ratios to DALL-E sizes
  if (ratio) {
    switch (ratio.toLowerCase()) {
      case '1:1':
        options.size = '1024x1024';
        break;
      case '16:9':
        options.size = '1792x1024';
        break;
      case '2:3':
        options.size = '1024x1792';
        break;
      default:
        options.size = '1024x1024'; // Default to square if invalid ratio
    }
  } else {
    options.size = '1024x1024'; // Default to square if no ratio specified
  }

  return {
    prompt: prompt.trim(),
    options
  };
}; 