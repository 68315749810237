import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ChatContainer } from '../components/chat';
import { InstructionsManager } from '../components/instructions/InstructionsManager';
import { useAuth } from '../shared/context/AuthContext';

const ProtectedRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  const { isDevelopmentMode } = useAuth();
  
  if (!isDevelopmentMode) {
    return <Navigate to="/chat" replace />;
  }
  
  return element;
};

export const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/chat" replace />} />
        <Route path="/chat" element={<ChatContainer />} />
        <Route path="/chat/:conversationId" element={<ChatContainer />} />
        <Route
          path="/instructions"
          element={
            <ProtectedRoute element={<InstructionsManager />} />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}; 