import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { MainLayout } from 'components/layouts';
import { Sidebar } from 'components/layouts/Sidebar';
import { streamCompletion } from 'shared/services/openaiService';
import { saveConversation, getConversations, deleteConversation } from 'shared/services/firebaseService';
import { useAbortController } from 'shared/hooks/useAbortController';
import { useAuth } from 'shared/context/AuthContext';
import { Message, MessageWithId, Conversation } from '../../shared/types';
import { ConversationList } from './ConversationList';
import { ChatContent } from './ChatContent';
import { TopBar } from './TopBar';
import { useSelectedInstruction } from '../../shared/context/SelectedInstructionContext';
import { parseImageGenerationCommand } from '../../shared/utils/imageGeneration';
import { generateImage } from '../../shared/services/imageGenerationService';
import { Box } from '@mui/material';

export const ChatContainer: React.FC = () => {
  const [messages, setMessages] = useState<MessageWithId[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [firebaseAvailable, setFirebaseAvailable] = useState(true);
  const [currentConversationId, setCurrentConversationId] = useState<string | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [currentConversation, setCurrentConversation] = useState<Conversation | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [messageError, setMessageError] = useState<string | null>(null);
  const { createAbortController, abortCurrentOperation } = useAbortController();
  const { user, isDevelopmentMode } = useAuth();
  const { selectedInstruction } = useSelectedInstruction();
  const [isScrolledUp, setIsScrolledUp] = React.useState(false);
  const simpleBarRef = React.useRef<{ getScrollElement: () => HTMLElement | null }>(null);
  const [accumulatedResponse, setAccumulatedResponse] = useState('');
  const loadConversationsTimeoutRef = useRef<NodeJS.Timeout>();
  const saveTimeoutRef = useRef<NodeJS.Timeout>();
  const lastSaveTimestampRef = useRef<number>(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setSidebarOpen(!isMobile);
  }, [isMobile]);

  const handleNewChat = () => {
    setMessages([]);
    setCurrentConversationId(null);
    setCurrentConversation(null);
    if (isMobile) setSidebarOpen(false);
  };

  const loadConversations = useCallback(async () => {
    try {
      const loadedConversations = await getConversations();
      setConversations(loadedConversations);
      setError(null);
    } catch (error) {
      console.error('Error loading conversations:', error);
      const errorMessage = error instanceof Error ? error.message : 'Error loading conversations';
      setError(errorMessage);
    }
  }, []);

  // Debounced conversation list update
  const debouncedLoadConversations = useCallback(() => {
    if (loadConversationsTimeoutRef.current) {
      clearTimeout(loadConversationsTimeoutRef.current);
    }
    loadConversationsTimeoutRef.current = setTimeout(() => {
      loadConversations();
    }, 1000); // Wait 1 second before updating the conversation list
  }, [loadConversations]);

  const debouncedSaveConversation = useCallback((messagesToSave: MessageWithId[]) => {
    // Remove the save if there's a pending save
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }

    // Always use the timeout to ensure we only save once
    saveTimeoutRef.current = setTimeout(() => {
      handleSaveConversation(messagesToSave);
    }, 2000);
  }, [currentConversationId]);

  useEffect(() => {
    loadConversations();
    return () => {
      if (loadConversationsTimeoutRef.current) {
        clearTimeout(loadConversationsTimeoutRef.current);
      }
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, [user, loadConversations]);

  const handleStopGeneration = () => {
    abortCurrentOperation();
    setIsLoading(false);
  };

  const handleSaveConversation = async (messagesToSave: MessageWithId[]) => {
    // Prevent duplicate saves
    const now = Date.now();
    if (now - lastSaveTimestampRef.current < 2000) {
      return;
    }
    lastSaveTimestampRef.current = now;

    try {
      const cleanMessages: Message[] = messagesToSave.map(({ id, ...msg }) => msg);
      
      if (!currentConversationId) {
        // First save - create new conversation
        console.log('[ChatContainer] Creating new conversation', {
          messageCount: messagesToSave.length,
          timestamp: new Date().toISOString()
        });
        
        const newConversationId = await saveConversation(cleanMessages, null);
        console.log('[ChatContainer] New conversation created', {
          conversationId: newConversationId,
          timestamp: new Date().toISOString()
        });
        
        // Ensure the ID is set before any other saves can happen
        setCurrentConversationId(newConversationId);
        debouncedLoadConversations();
      } else {
        // Update existing conversation
        console.log('[ChatContainer] Updating existing conversation', {
          conversationId: currentConversationId,
          messageCount: messagesToSave.length,
          timestamp: new Date().toISOString()
        });
        await saveConversation(cleanMessages, currentConversationId);
      }
      
      setError(null);
    } catch (error) {
      console.error('[ChatContainer] Error saving to Firebase:', error);
      setError('Error saving conversation');
      setFirebaseAvailable(false);
    }
  };

  const handleSendMessage = async (message: string) => {
    if (!message.trim()) return;

    const controller = createAbortController();
    const signal = controller.signal;

    // Check for image generation command
    const imageCommand = parseImageGenerationCommand(message);
    
    const newMessage: MessageWithId = {
      content: message,
      role: 'user',
      timestamp: new Date(),
    };

    // Add user message to chat and trigger initial save
    setMessages(prev => {
      const updatedMessages = [...prev, newMessage];
      if (firebaseAvailable && !currentConversationId) {
        // Only trigger immediate save for the first message
        debouncedSaveConversation(updatedMessages);
      }
      return updatedMessages;
    });
    setIsLoading(true);
    setMessageError(null);

    const aiResponseId = Date.now();
    const aiMessage: MessageWithId = {
      id: aiResponseId,
      content: imageCommand ? `Generating image for prompt: ${imageCommand.prompt}` : '',
      role: 'assistant',
      timestamp: new Date(),
      metadata: {
        customRole: selectedInstruction?.title || 'basis',
        color: selectedInstruction?.hatColor || theme.palette.text.secondary,
        isGeneratingImage: !!imageCommand,
        imageSize: imageCommand?.options.size
      }
    };

    // Add AI message to chat immediately with loading state
    setMessages(prev => [...prev, aiMessage]);

    try {
      if (imageCommand) {
        // Handle image generation
        const imageUrl = await generateImage(imageCommand.prompt, imageCommand.options);
        
        const updatedAiMessage: MessageWithId = {
          ...aiMessage,
          metadata: {
            ...aiMessage.metadata,
            imageUrl,
            isGeneratingImage: false
          }
        };

        setMessages(prev => {
          const updatedMessages = prev.map(msg =>
            msg.id === aiResponseId ? updatedAiMessage : msg
          );
          if (firebaseAvailable) {
            console.log('[ChatContainer] Triggering save after image generation', {
              hasImageUrl: !!imageUrl,
              isAborted: signal.aborted,
              timestamp: new Date().toISOString()
            });
            debouncedSaveConversation(updatedMessages);
          }
          return updatedMessages;
        });

      } else {
        // Handle regular chat message
        let accumulatedResponse = '';
        const previousMessages = messages.map(msg => ({
          role: msg.role,
          content: msg.content
        }));

        for await (const chunk of streamCompletion(message, previousMessages, signal, selectedInstruction)) {
          if (signal.aborted) {
            break;
          }
          accumulatedResponse += chunk;
          
          // Check for image generation command in accumulated response
          const streamImageCommand = parseImageGenerationCommand(accumulatedResponse);
          if (streamImageCommand) {
            // Update message with loading state
            setMessages(prev => prev.map(msg =>
              msg.id === aiResponseId
                ? {
                    ...msg,
                    content: accumulatedResponse,
                    metadata: {
                      ...msg.metadata,
                      isGeneratingImage: true,
                      imageSize: streamImageCommand.options.size
                    }
                  }
                : msg
            ));

            try {
              const imageUrl = await generateImage(streamImageCommand.prompt, streamImageCommand.options);
              
              setMessages(prev => {
                const updatedMessages = prev.map(msg =>
                  msg.id === aiResponseId
                    ? {
                        ...msg,
                        content: accumulatedResponse,
                        metadata: {
                          ...msg.metadata,
                          imageUrl,
                          imageSize: streamImageCommand.options.size,
                          isGeneratingImage: false
                        }
                      }
                    : msg
                );
                if (firebaseAvailable && !signal.aborted) {
                  console.log('[ChatContainer] Triggering save after image generation', {
                    hasImageUrl: !!imageUrl,
                    isAborted: signal.aborted,
                    timestamp: new Date().toISOString()
                  });
                  debouncedSaveConversation(updatedMessages);
                }
                return updatedMessages;
              });
            } catch (err) {
              console.error('[Image Gen] Failed to generate image from AI response:', err);
              const errorMessage = err instanceof Error ? err.message : 'Failed to generate image';
              
              // Create error message
              const errorMsg: MessageWithId = {
                id: Date.now(),
                content: errorMessage,
                role: 'assistant',
                timestamp: new Date(),
                metadata: {
                  isError: true,
                  customRole: 'Error',
                  color: theme.palette.error.main
                }
              };

              setMessages(prev => {
                const messagesWithoutFailed = prev.filter(msg => msg.id !== aiResponseId);
                const updatedMessages = [...messagesWithoutFailed, errorMsg];
                if (firebaseAvailable) {
                  console.log('[ChatContainer] Triggering save after error message', {
                    errorMessage: errorMsg.content,
                    timestamp: new Date().toISOString()
                  });
                  debouncedSaveConversation(updatedMessages);
                }
                return updatedMessages;
              });
            }
          } else {
            // Regular text update
            setMessages(prev => {
              const updatedMessages = prev.map(msg =>
                msg.id === aiResponseId
                  ? {
                      ...msg,
                      content: accumulatedResponse,
                      metadata: {
                        customRole: selectedInstruction?.title || 'basis',
                        color: selectedInstruction?.hatColor || theme.palette.text.secondary
                      }
                    }
                  : msg
              );
              // Only trigger save once when we have significant content
              if (firebaseAvailable && !signal.aborted && accumulatedResponse.length > 50) {
                debouncedSaveConversation(updatedMessages);
              }
              return updatedMessages;
            });
          }
        }
      }
    } catch (err) {
      console.error('[Image Gen] Error in message handling:', err);
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      
      // Create error message
      const errorMsg: MessageWithId = {
        id: Date.now(),
        content: errorMessage,
        role: 'assistant',
        timestamp: new Date(),
        metadata: {
          isError: true,
          customRole: 'Error',
          color: theme.palette.error.main
        }
      };

      setMessages(prev => {
        // Remove the failed AI message and add the error message
        const messagesWithoutFailed = prev.filter(msg => msg.id !== aiResponseId);
        const updatedMessages = [...messagesWithoutFailed, errorMsg];
        if (firebaseAvailable) {
          console.log('[ChatContainer] Triggering save after error message', {
            errorMessage: errorMsg.content,
            timestamp: new Date().toISOString()
          });
          debouncedSaveConversation(updatedMessages);
        }
        return updatedMessages;
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadConversation = (conversation: Conversation) => {
    setMessages(conversation.messages);
    setCurrentConversationId(conversation.id);
    setCurrentConversation(conversation);
    setError(null);
    setMessageError(null); // Clear any existing message errors when loading a new conversation
  };

  const handleDeleteConversation = async (conversationId: string) => {
    try {
      await deleteConversation(conversationId);
      setConversations(prev => prev.filter(conv => conv.id !== conversationId));
      if (conversationId === currentConversationId) {
        setMessages([]);
        setCurrentConversationId(null);
        setCurrentConversation(null);
      }
      setError(null);
    } catch (error) {
      setError('Error deleting conversation');
    }
  };

  const getConversationTitle = () => {
    if (!currentConversation) return 'New Chat';
    if (currentConversation.title) return currentConversation.title;
    
    const firstUserMessage = currentConversation.messages?.find(msg => msg.role === 'user');
    return firstUserMessage 
      ? firstUserMessage.content.slice(0, 30) + (firstUserMessage.content.length > 30 ? '...' : '')
      : 'Nieuwe conversatie';
  };

  const handleToggleSidebar = () => {
    setSidebarOpen(prev => !prev);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  const handleScrollToBottom = () => {
    const simpleBar = simpleBarRef.current?.getScrollElement();
    if (simpleBar) {
      simpleBar.scrollTo({
        top: simpleBar.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  return (
    <MainLayout
      sidebar={
        <Sidebar open={sidebarOpen} onClose={handleCloseSidebar}>
          <ConversationList
            conversations={conversations}
            onSelectConversation={loadConversation}
            onDeleteConversation={handleDeleteConversation}
            error={error}
          />
        </Sidebar>
      }
      sidebarOpen={sidebarOpen}
      onSidebarClose={handleCloseSidebar}
    >
      <TopBar
        onNewChat={handleNewChat}
        onToggleSidebar={handleToggleSidebar}
        title={getConversationTitle()}
        sidebarOpen={sidebarOpen}
      />
      <ChatContent
        messages={messages}
        isLoading={isLoading}
        error={messageError}
        onSendMessage={handleSendMessage}
        onStopGeneration={handleStopGeneration}
        onScrollStateChange={setIsScrolledUp}
        isScrolledUp={isScrolledUp}
        onScrollToBottom={handleScrollToBottom}
      />
    </MainLayout>
  );
}; 