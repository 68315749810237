import React, { useState, useMemo } from 'react';
import {
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Typography,
  ListItemIcon,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCustomInstructions } from '../../../shared/context/CustomInstructionsContext';
import { useSelectedInstruction } from '../../../shared/context/SelectedInstructionContext';
import { useAuth } from '../../../shared/context/AuthContext';
import { HatIcon } from '../../common/HatIcon/HatIcon';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  alignSelf: 'center',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  gap: theme.spacing(2),
  padding: theme.spacing(1, 2),
  '& .MuiListItemIcon-root': {
    minWidth: 'auto',
  },
}));

export const InstructionSelector: React.FC = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { instructions } = useCustomInstructions();
  const { selectedInstruction, setSelectedInstruction } = useSelectedInstruction();
  const { user } = useAuth();

  // Filter instructions to only show public ones or ones created by the current user
  const availableInstructions = useMemo(() => {
    return instructions.filter(instruction => 
      instruction.isPublic || instruction.createdBy === user?.uid
    );
  }, [instructions, user?.uid]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (instruction: typeof selectedInstruction) => {
    setSelectedInstruction(instruction);
    handleClose();
  };

  return (
    <>
      <Tooltip title={selectedInstruction?.title || 'basis'}>
        <StyledIconButton
          onClick={handleClick}
          aria-label="Select custom instruction"
        >
          <HatIcon color={selectedInstruction?.hatColor || theme.palette.text.secondary} />
        </StyledIconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledMenuItem 
          onClick={() => handleSelect(null)}
          selected={!selectedInstruction}
        >
          <ListItemIcon>
            <FontAwesomeIcon 
              icon="circle" 
              style={{ 
                color: theme.palette.text.secondary,
                opacity: 0.7,
                fontSize: '0.8rem'
              }} 
            />
          </ListItemIcon>
          <Typography>basis</Typography>
        </StyledMenuItem>
        {availableInstructions.map((instruction) => (
          <StyledMenuItem
            key={instruction.id}
            onClick={() => handleSelect(instruction)}
            selected={selectedInstruction?.id === instruction.id}
          >
            <ListItemIcon>
              <HatIcon color={instruction.hatColor} />
            </ListItemIcon>
            <Typography>{instruction.title}</Typography>
          </StyledMenuItem>
        ))}
      </Menu>
    </>
  );
}; 