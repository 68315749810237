import React from 'react';
import { useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

interface HatIconProps {
  color?: string;
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps<Theme>;
}

const sizeMap = {
  small: '1rem',
  medium: '1.2rem',
  large: '1.5rem',
} as const;

export const HatIcon: React.FC<HatIconProps> = ({ 
  color,
  size = 'medium',
  sx = {}
}) => {
  const theme = useTheme();

  const iconStyle: CSSProperties = {
    color: color || theme.palette.primary.main,
    fontSize: sizeMap[size],
    transition: 'color 200ms ease-in-out',
    ...(sx as CSSProperties)
  };

  return (
    <FontAwesomeIcon 
      icon="hat-wizard" 
      style={iconStyle}
    />
  );
}; 