import React from 'react';
import { ThemeProvider, CssBaseline, Box } from '@mui/material';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlus,
  faPaperPlane,
  faTrash,
  faMicrophone,
  faMicrophoneSlash,
  faVolumeUp,
  faStop,
  faBug,
  faRobot,
  faArrowLeft,
  faArrowDown,
  faPencilAlt,
  faHatWizard,
  faCircle,
  faEllipsisV
} from '@fortawesome/free-solid-svg-icons';
import { createTheme } from './shared/theme';
import { AppRoutes } from './routes';
import { AuthProvider, useAuth } from './shared/context/AuthContext';
import { CustomInstructionsProvider } from './shared/context/CustomInstructionsContext';
import { SelectedInstructionProvider } from './shared/context/SelectedInstructionContext';

// Add FontAwesome icons to library
library.add(
  faPlus,
  faPaperPlane,
  faTrash,
  faMicrophone,
  faMicrophoneSlash,
  faVolumeUp,
  faStop,
  faBug,
  faRobot,
  faArrowLeft,
  faArrowDown,
  faPencilAlt,
  faHatWizard,
  faCircle,
  faEllipsisV
);

const AppContent: React.FC = () => {
  const theme = React.useMemo(() => createTheme('light'), []);
  const { loading } = useAuth();

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        Loading...
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CustomInstructionsProvider>
        <SelectedInstructionProvider>
          <AppRoutes />
        </SelectedInstructionProvider>
      </CustomInstructionsProvider>
    </ThemeProvider>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
};

export default App; 