import { useState, useCallback, useRef, useEffect } from 'react';
import OpenAI from 'openai';
import { OPENAI_CONFIG, createOpenAIClient } from '../config/openai';

interface SpeechRecognitionEvent extends Event {
  resultIndex: number;
  results: {
    [key: number]: {
      isFinal: boolean;
      [key: number]: {
        transcript: string;
      };
    };
  };
}

interface SpeechRecognitionError extends Event {
  error: string;
}

interface SpeechRecognitionInstance extends EventTarget {
  continuous: boolean;
  interimResults: boolean;
  lang: string;
  maxAlternatives: number;
  onstart: () => void;
  onresult: (event: SpeechRecognitionEvent) => void;
  onerror: (event: SpeechRecognitionError) => void;
  onend: () => void;
  start: () => void;
  stop: () => void;
}

declare global {
  interface Window {
    SpeechRecognition: {
      new(): SpeechRecognitionInstance;
    };
    webkitSpeechRecognition: {
      new(): SpeechRecognitionInstance;
    };
  }
}

interface UseSpeechReturn {
  isListening: boolean;
  error: string | null;
  speechSupported: boolean;
  startListening: (onResult: (transcript: string) => void) => void;
  stopListening: () => void;
  speak: (text: string, onEnd?: () => void) => Promise<void>;
  stopSpeaking: () => void;
  isSpeaking: boolean;
}

const useSpeech = (apiKey?: string): UseSpeechReturn => {
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [speechSupported] = useState('SpeechRecognition' in window || 'webkitSpeechRecognition' in window);
  const recognitionRef = useRef<SpeechRecognitionInstance | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const openaiClient = useRef<OpenAI | null>(null);

  useEffect(() => {
    if (apiKey && !openaiClient.current) {
      openaiClient.current = createOpenAIClient(apiKey);
    }
  }, [apiKey]);

  // Initialize speech recognition
  const recognition = useCallback(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognitionInstance = new SpeechRecognition();
    
    recognitionInstance.continuous = false;
    recognitionInstance.interimResults = true;
    recognitionInstance.lang = 'en-US';
    recognitionInstance.maxAlternatives = 1;
    
    return recognitionInstance;
  }, []);

  const stopListening = useCallback(() => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRef.current = null;
    }
    setIsListening(false);
  }, []);

  const startListening = useCallback((onResult: (transcript: string) => void) => {
    if (!speechSupported) {
      setError('Speech recognition is not supported in this browser');
      return;
    }

    if (isListening) {
      stopListening();
      return;
    }

    setError(null);
    const recognitionInstance = recognition();
    recognitionRef.current = recognitionInstance;

    recognitionInstance.onstart = () => {
      setIsListening(true);
      setError(null);
    };

    recognitionInstance.onresult = (event: SpeechRecognitionEvent) => {
      const current = event.resultIndex;
      const transcript = event.results[current][0].transcript;
      
      if (event.results[current].isFinal) {
        onResult(transcript);
        recognitionInstance.stop();
      }
    };

    recognitionInstance.onerror = (event: SpeechRecognitionError) => {
      setError(`Speech recognition error: ${event.error}`);
      setIsListening(false);
    };

    recognitionInstance.onend = () => {
      setIsListening(false);
      recognitionRef.current = null;
    };

    try {
      recognitionInstance.start();
    } catch (err) {
      setError(`Speech recognition start error: ${err instanceof Error ? err.message : String(err)}`);
      setIsListening(false);
      recognitionRef.current = null;
    }
  }, [speechSupported, recognition, isListening, stopListening]);

  const stopSpeaking = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current = null;
    }
    setIsSpeaking(false);
  }, []);

  const speak = useCallback(async (text: string, onEnd?: () => void) => {
    if (!openaiClient.current) {
      setError('OpenAI client not initialized. Please provide an API key.');
      return;
    }

    try {
      stopSpeaking();
      setIsSpeaking(true);

      const response = await openaiClient.current.audio.speech.create({
        model: OPENAI_CONFIG.tts.model,
        voice: OPENAI_CONFIG.tts.voice,
        input: text,
        response_format: OPENAI_CONFIG.tts.format,
      });

      const audioBlob = new Blob([await response.arrayBuffer()], { type: 'audio/mp3' });
      const audioUrl = URL.createObjectURL(audioBlob);
      
      const audio = new Audio(audioUrl);
      audioRef.current = audio;
      
      audio.onended = () => {
        URL.revokeObjectURL(audioUrl);
        audioRef.current = null;
        setIsSpeaking(false);
        onEnd?.();
      };

      audio.onerror = (e) => {
        setError(`Audio playback error: ${e.toString()}`);
        setIsSpeaking(false);
        URL.revokeObjectURL(audioUrl);
        onEnd?.();
      };

      await audio.play().catch((err) => {
        setError(`Audio play error: ${err.toString()}`);
        setIsSpeaking(false);
        URL.revokeObjectURL(audioUrl);
        onEnd?.();
        throw err;
      });
    } catch (err) {
      setError(`Text-to-speech error: ${err instanceof Error ? err.message : String(err)}`);
      setIsSpeaking(false);
      onEnd?.();
    }
  }, [stopSpeaking]);

  return {
    isListening,
    error,
    speechSupported,
    startListening,
    stopListening,
    speak,
    stopSpeaking,
    isSpeaking
  };
};

export default useSpeech; 