import React from 'react';
import { Box, Drawer, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { DRAWER_WIDTH, TOP_BAR_HEIGHT } from '../../../shared/constants';

const SidebarRoot = styled(Box)(({ theme }) => ({
  width: DRAWER_WIDTH,
  backgroundColor: theme.palette.background.paper,
  borderRight: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingTop: TOP_BAR_HEIGHT,
  '& .simplebar-scrollbar::before': {
    background: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.3)' 
      : 'rgba(0, 0, 0, 0.3)',
  },
  '& .simplebar-track.simplebar-vertical': {
    right: 'auto',
    left: 0,
  },
}));

export interface SidebarProps {
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

export const Sidebar: React.FC<SidebarProps> = React.memo(({ children, open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'persistent'}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          border: 'none',
        },
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile
      }}
    >
      <SidebarRoot>
        <SimpleBar style={{ height: '100%', flex: 1 }}>
          {children}
        </SimpleBar>
      </SidebarRoot>
    </Drawer>
  );
}); 