import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const ContentRoot = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  backgroundColor: theme.palette.background.default,
}));

export interface ContentProps {
  children?: React.ReactNode;
}

export const Content: React.FC<ContentProps> = ({ children }) => {
  return <ContentRoot>{children}</ContentRoot>;
}; 