import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { CustomInstruction } from '../../../shared/types/instructions.types';
import { useCustomInstructions } from '../../../shared/context/CustomInstructionsContext';
import { HatIcon } from '../HatIcon/HatIcon';

interface InstructionCardProps {
  instruction: CustomInstruction;
  onEdit?: (instruction: CustomInstruction) => void;
  onDelete?: (id: string) => void;
}

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  marginBottom: theme.spacing(2),
  transition: theme.transitions.create(['box-shadow', 'transform'], {
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  width: 40,
  height: 40,
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
  '& svg': {
    fontSize: '20px',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const TruncatedTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const ActionContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(0.5),
}));

export const InstructionCard: React.FC<InstructionCardProps> = ({
  instruction,
  onEdit,
  onDelete,
}) => {
  const { canEditInstruction } = useCustomInstructions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showFullActions, setShowFullActions] = useState(true);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const canEdit = canEditInstruction(instruction);

  // Check if we need to show the overflow menu on mount and window resize
  React.useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        // Assuming each icon button is roughly 40px wide and we want some padding
        setShowFullActions(containerWidth >= 180);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit?.(instruction);
    handleMenuClose();
  };

  const handleDelete = () => {
    onDelete?.(instruction.id);
    handleMenuClose();
  };

  const renderActions = () => {
    if (!canEdit) return null;

    const actions = (
      <>
        <StyledIconButton onClick={handleEdit} size="small" aria-label="Edit">
          <FontAwesomeIcon icon="pencil-alt" />
        </StyledIconButton>
        <StyledIconButton onClick={handleDelete} size="small" aria-label="Delete">
          <FontAwesomeIcon icon="trash" />
        </StyledIconButton>
      </>
    );

    const menuItems = (
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <FontAwesomeIcon icon="pencil-alt" />
          </ListItemIcon>
          <ListItemText>Bewerken</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <FontAwesomeIcon icon="trash" />
          </ListItemIcon>
          <ListItemText>Verwijderen</ListItemText>
        </MenuItem>
      </Menu>
    );

    return (
      <ActionContainer ref={containerRef}>
        {showFullActions ? (
          actions
        ) : (
          <>
            <StyledIconButton
              onClick={handleMenuOpen}
              size="small"
              aria-label="More actions"
            >
              <FontAwesomeIcon icon="ellipsis-v" />
            </StyledIconButton>
            {menuItems}
          </>
        )}
      </ActionContainer>
    );
  };

  return (
    <StyledCard>
      <CardContent>
        <CardHeader>
          <HatIcon color={instruction.hatColor} />
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="h6" component="div">
                {instruction.title}
              </Typography>
              <Chip
                size="small"
                label={instruction.isPublic ? "Openbaar" : "Privé"}
                color={instruction.isPublic ? "primary" : "default"}
                variant="outlined"
                sx={{ height: 20 }}
              />
            </Box>
          </Box>
          {renderActions()}
        </CardHeader>
        <TruncatedTypography color="text.secondary">
          {instruction.content}
        </TruncatedTypography>
      </CardContent>
    </StyledCard>
  );
}; 