import React from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface ButtonProps extends Omit<MuiButtonProps, 'variant'> {
  variant?: 'contained' | 'outlined' | 'text';
  compact?: boolean;
}

const StyledButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'compact',
})<{ compact?: boolean }>(({ theme, compact }) => ({
  fontFamily: 'Montserrat, sans-serif',
  minWidth: compact ? '40px' : 'unset',
  width: compact ? '40px' : 'auto',
  height: compact ? '40px' : 'auto',
  padding: compact ? theme.spacing(1) : undefined,
  borderRadius: compact ? theme.shape.borderRadius.small : theme.shape.borderRadius.card,
  transition: theme.transitions.create(['background-color', 'box-shadow', 'border-color', 'color'], {
    duration: theme.transitions.duration.short,
  }),
  '&.MuiButton-contained': {
    background: theme.palette.gradient.primary,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.gradient.hover,
    },
    '&.Mui-disabled': {
      background: theme.palette.action.disabled,
      color: theme.palette.text.disabled,
    },
  },
  '&.MuiButton-outlined': {
    borderColor: theme.palette.text.primary,
    color: theme.palette.text.primary,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return <StyledButton ref={ref} {...props} />;
});

export default Button; 