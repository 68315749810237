import React from 'react';
import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type TextFieldProps = MuiTextFieldProps & {
  maxRows?: number;
};

const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontFamily: 'Montserrat, sans-serif',
    color: theme.palette.text.primary,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(8px)',
    minHeight: '88px',
    maxHeight: '200px',
    borderRadius: theme.shape.borderRadius.small,
    alignItems: 'center',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.5),
      minHeight: '24px',
      color: theme.palette.text.primary,
      '&::placeholder': {
        color: theme.palette.text.secondary,
        opacity: 0.7,
      },
      '&:focus': {
        color: theme.palette.text.primary,
      },
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.divider,
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.text.secondary,
      },
    },
    '& fieldset': {
      borderColor: theme.palette.divider,
      borderRadius: theme.shape.borderRadius.small,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.text.secondary,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
  },
}));

export const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => {
  return <StyledTextField {...props} ref={ref} />;
});

export default TextField; 