import React from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ImageModalProps {
  open: boolean;
  onClose: () => void;
  imageUrl: string | null;
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
  outline: 'none',
  maxWidth: '90vw',
  maxHeight: '90vh',
};

const imageStyle = {
  maxWidth: '100%',
  maxHeight: 'calc(90vh - 4rem)',
  objectFit: 'contain' as const,
};

export const ImageModal: React.FC<ImageModalProps> = ({ open, onClose, imageUrl }) => {
  if (!imageUrl) return null;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="image-modal"
    >
      <Box sx={modalStyle}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'action.hover',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <img src={imageUrl} alt="Generated" style={imageStyle} />
      </Box>
    </Modal>
  );
}; 