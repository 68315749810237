import OpenAI from 'openai';

type TTSVoice = 'alloy' | 'echo' | 'fable' | 'onyx' | 'nova' | 'shimmer';
type TTSModel = 'tts-1' | 'tts-1-hd';
type TTSFormat = 'mp3' | 'opus' | 'aac' | 'flac' | 'pcm';

interface TTSConfig {
  model: TTSModel;
  voice: TTSVoice;
  format: TTSFormat;
}

export const OPENAI_CONFIG = {
  tts: {
    model: 'tts-1' as TTSModel,
    voice: 'alloy' as TTSVoice,
    format: 'mp3' as TTSFormat,
  } satisfies TTSConfig,
};

export const createOpenAIClient = (apiKey: string) => {
  return new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true
  });
}; 