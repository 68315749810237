import React, { useState, useEffect } from 'react';
import { 
  List, 
  ListItem, 
  ListItemText, 
  IconButton, 
  Box,
  ListItemSecondaryAction,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Conversation } from '../../../shared/types';

export interface ConversationListProps {
  conversations: Conversation[];
  onSelectConversation: (conversation: Conversation) => void;
  onDeleteConversation: (id: string) => void;
  error?: string | null;
}

const ListContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}));

const ScrollableList = styled(List)(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  padding: theme.spacing(1),
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.divider,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.text.secondary,
  },
}));

interface StyledListItemProps {
  onClick: () => void;
}

const ConversationItem = styled(ListItem)<StyledListItemProps>(({ theme }) => ({
  borderRadius: theme.shape.borderRadius.card,
  marginBottom: theme.spacing(1),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.background.hover,
  },
}));

const ErrorMessage = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const SecondaryIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: theme.spacing(1),
  '&:hover': {
    color: theme.palette.error.main,
  },
  '& svg': {
    fontSize: '20px',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const LoadingIconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
});

export const ConversationList: React.FC<ConversationListProps> = React.memo(({
  conversations,
  onSelectConversation,
  onDeleteConversation,
  error
}) => {
  const [deletingId, setDeletingId] = useState<string | null>(null);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('ConversationList received new conversations:', {
      count: conversations.length,
      titles: conversations.map(c => c.title)
    });
  }, [conversations]);

  const handleDelete = async (e: React.MouseEvent, conversationId: string) => {
    e.stopPropagation();
    setDeletingId(conversationId);
    try {
      await onDeleteConversation(conversationId);
    } finally {
      setDeletingId(null);
    }
  };

  return (
    <ListContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <ScrollableList>
        {conversations.map((conversation) => (
          <ConversationItem
            key={conversation.id}
            onClick={() => onSelectConversation(conversation)}
          >
            <ListItemText
              primary={conversation.title}
              secondary={new Date(conversation.lastUpdated).toLocaleDateString()}
            />
            <ListItemSecondaryAction>
              {deletingId === conversation.id ? (
                <LoadingIconWrapper>
                  <CircularProgress size={20} />
                </LoadingIconWrapper>
              ) : (
                <SecondaryIconButton
                  onClick={(e) => handleDelete(e, conversation.id)}
                  aria-label="Delete conversation"
                  size="small"
                >
                  <FontAwesomeIcon 
                    icon="trash" 
                    style={{
                      transform: 'scale(0.85)',
                    }}
                  />
                </SecondaryIconButton>
              )}
            </ListItemSecondaryAction>
          </ConversationItem>
        ))}
      </ScrollableList>
    </ListContainer>
  );
}); 