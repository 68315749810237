export const DRAWER_WIDTH: number = 280;
export const TOP_BAR_HEIGHT: number = 64;

// Environment and development mode settings
export const ENVIRONMENT = {
  isLocalhost: (): boolean => {
    const hostname = window.location.hostname;
    const isLocal = hostname === 'localhost' || 
           hostname === '[::1]' || 
           !!hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/);
    
    // eslint-disable-next-line no-console
    console.log('Localhost check:', {
      hostname,
      isLocal,
      exactMatch: hostname === 'localhost',
      ipv6Match: hostname === '[::1]',
      ipv4Match: !!hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    });
    
    return isLocal;
  },
  isAllowedDomain: (email: string | null | undefined): boolean => {
    if (!email) return false;
    const isAllowed = email.endsWith('@tmi.one');
    // eslint-disable-next-line no-console
    console.log('Domain check:', { email, isAllowed });
    return isAllowed;
  }
} as const; 