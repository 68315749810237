import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  getAuth, 
  signInWithPopup, 
  GoogleAuthProvider, 
  signOut, 
  onAuthStateChanged,
  User,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth';
import { app } from '../../config/firebase';
import { setDevelopmentMode } from '../services/firebaseService';
import { ENVIRONMENT } from '../constants';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  error: string | null;
  isDevelopmentMode: boolean;
  isSessionOnly: boolean;
  toggleDevelopmentMode: () => void;
  signInWithGoogle: () => Promise<void>;
  logout: () => Promise<void>;
  canEnableDevelopmentMode: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isDevelopmentMode, setIsDevelopmentMode] = useState(() => {
    // Initialize dev mode from localStorage if user is eligible
    const savedDevMode = localStorage.getItem('devMode') === 'true';
    return savedDevMode;
  });
  
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();

  // Check if development mode can be enabled
  const canEnableDevelopmentMode = ENVIRONMENT.isLocalhost() || ENVIRONMENT.isAllowedDomain(user?.email);

  // Log state changes for debugging
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Development mode state:', {
      isDevelopmentMode,
      canEnable: canEnableDevelopmentMode,
      isLocal: ENVIRONMENT.isLocalhost(),
      userEmail: user?.email,
      hasAllowedDomain: ENVIRONMENT.isAllowedDomain(user?.email),
      reason: ENVIRONMENT.isLocalhost() ? 'localhost' : ENVIRONMENT.isAllowedDomain(user?.email) ? 'tmi.one email' : 'not allowed'
    });
  }, [isDevelopmentMode, canEnableDevelopmentMode, user]);

  // Set up persistence
  useEffect(() => {
    // eslint-disable-next-line no-console
    setPersistence(auth, browserLocalPersistence).catch(console.error);
  }, [auth]);

  // Session-only state is when there's no authenticated user
  const isSessionOnly = !user;

  // Handle auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      // eslint-disable-next-line no-console
      console.log('Auth state changed:', {
        currentUser: {
          uid: currentUser?.uid,
          email: currentUser?.email
        },
        isDevelopmentMode
      });

      // If development mode is enabled but user is not allowed, disable it
      if (isDevelopmentMode && !canEnableDevelopmentMode) {
        // eslint-disable-next-line no-console
        console.log('Disabling development mode - user not allowed');
        setIsDevelopmentMode(false);
        setDevelopmentMode(false);
        localStorage.removeItem('devMode');
      }

      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth, isDevelopmentMode, canEnableDevelopmentMode]);

  // Add this useEffect to log the user ID
  useEffect(() => {
    if (user) {
      console.log('Your Firebase Auth UID:', user.uid);
      console.log('Your email:', user.email);
    }
  }, [user]);

  const signInWithGoogle = async () => {
    try {
      setError(null);
      const result = await signInWithPopup(auth, googleProvider);
      setUser(result.user);
    } catch (error) {
      setError('Failed to sign in with Google');
      // eslint-disable-next-line no-console
      console.error('Auth Error:', error);
    }
  };

  const logout = async () => {
    try {
      setError(null);
      await signOut(auth);
      setUser(null);
      // Disable dev mode when logging out
      setIsDevelopmentMode(false);
      setDevelopmentMode(false);
      localStorage.removeItem('devMode');
    } catch (error) {
      setError('Failed to log out');
      // eslint-disable-next-line no-console
      console.error('Auth Error:', error);
    }
  };

  const toggleDevelopmentMode = () => {
    if (!canEnableDevelopmentMode) {
      // eslint-disable-next-line no-console
      console.warn('Development mode cannot be enabled - requirements not met');
      return;
    }

    const newMode = !isDevelopmentMode;
    // eslint-disable-next-line no-console
    console.log('Toggling development mode:', {
      previous: isDevelopmentMode,
      new: newMode,
      user: user?.email
    });
    
    // Toggle dev mode while maintaining current user
    setIsDevelopmentMode(newMode);
    setDevelopmentMode(newMode);
    
    if (newMode) {
      localStorage.setItem('devMode', 'true');
    } else {
      localStorage.removeItem('devMode');
    }
  };

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        loading, 
        error,
        isDevelopmentMode,
        isSessionOnly,
        toggleDevelopmentMode,
        signInWithGoogle, 
        logout,
        canEnableDevelopmentMode
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}; 