import React, { useState, useRef, useEffect } from 'react';
import { Box, IconButton, Chip, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '../../common';
import useSpeech from '../../../shared/hooks/useSpeech';
import { InstructionSelector } from '../InstructionSelector/InstructionSelector';
import { useAuth } from '../../../shared/context/AuthContext';

export interface ChatInputProps {
  onSendMessage: (message: string) => void;
  onStopGeneration?: () => void;
  isLoading?: boolean;
  onHeightChange?: (height: number) => void;
  isScrolledUp?: boolean;
  onScrollToBottom?: () => void;
}

const InputContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'transparent',
  position: 'relative',
  width: '100%',
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  width: '100%',
  position: 'relative',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(8px)',
  borderRadius: theme.shape.borderRadius.small,
  border: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(0, 1),
  transition: theme.transitions.create(['border-color'], {
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': {
    borderColor: theme.palette.text.secondary,
  },
  '&:focus-within': {
    borderColor: theme.palette.primary.main,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  flex: 1,
  '& .MuiInputBase-root': {
    minHeight: '56px',
    maxHeight: '200px',
    padding: theme.spacing(1.5, 1),
    paddingRight: theme.spacing(9),
    border: 'none',
    backgroundColor: 'transparent !important',
    backdropFilter: 'none',
    '&:before, &:after': {
      display: 'none',
    },
    '& fieldset': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      padding: 0,
      maxHeight: '180px',
      overflow: 'auto',
      backgroundColor: 'transparent',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.divider,
        borderRadius: '4px',
        border: '2px solid transparent',
        backgroundClip: 'padding-box',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.text.secondary,
      },
    },
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  padding: theme.spacing(1),
  alignItems: 'center',
  position: 'absolute',
  right: theme.spacing(0.5),
  top: '50%',
  transform: 'translateY(-50%)',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    color: theme.palette.action.disabled,
  },
  '&.MuiIconButton-colorPrimary': {
    color: theme.palette.action.active,
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(1)',
        opacity: 1,
      },
      '50%': {
        transform: 'scale(1.1)',
        opacity: 0.9,
      },
      '100%': {
        transform: 'scale(1)',
        opacity: 1,
      },
    },
    animation: 'pulse 2s infinite ease-in-out',
  },
  '& svg': {
    fontSize: '20px',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const ScrollToBottomChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.secondary,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    cursor: 'pointer',
  },
  height: 24,
  position: 'absolute',
  top: 5,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 3,
  '& .MuiChip-label': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: '0.75rem',
    textTransform: 'none',
    textAlign: 'center',
  },
  '& .MuiChip-icon': {
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1),
    marginRight: -theme.spacing(1),
  },
}));

export const ChatInput: React.FC<ChatInputProps> = ({ 
  onSendMessage, 
  onStopGeneration,
  isLoading = false,
  onHeightChange,
  isScrolledUp = false,
  onScrollToBottom
}) => {
  const [message, setMessage] = useState('');
  const { startListening, stopListening, isListening, speechSupported } = useSpeech();
  const { isDevelopmentMode } = useAuth();
  const textFieldRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current && onHeightChange) {
        onHeightChange(containerRef.current.offsetHeight);
      }
    };

    const resizeObserver = new ResizeObserver(updateHeight);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [onHeightChange]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!isLoading && message.trim()) {
      onSendMessage(message.trim());
      setMessage('');
    }
  };

  const handleStopClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onStopGeneration) {
      onStopGeneration();
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (isLoading && onStopGeneration) {
        onStopGeneration();
      } else if (message.trim()) {
        handleSubmit(e);
      }
    }
  };

  const handleSpeechInput = () => {
    if (isListening) {
      stopListening();
      return;
    }
    startListening((transcript: string) => {
      setMessage(prev => {
        const newMessage = prev.trim() ? `${prev} ${transcript}` : transcript;
        return newMessage;
      });
    });
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '100%' }}>
      <InputContainer ref={containerRef}>
        <Fade in={isScrolledUp} timeout={300}>
          <Box>
            <ScrollToBottomChip
              onClick={onScrollToBottom}
              icon={<FontAwesomeIcon icon="arrow-down" />}
              label="nieuwe berichten"
              clickable
            />
          </Box>
        </Fade>
        <InputWrapper>
          <InstructionSelector />
          <StyledTextField
            multiline
            maxRows={10}
            placeholder={isListening ? 'Aan het luisteren...' : 'Schrijf een prompt...'}
            value={message}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            fullWidth
            inputRef={textFieldRef}
          />
          <ButtonContainer>
            {speechSupported && isDevelopmentMode && (
              <StyledIconButton
                onClick={handleSpeechInput}
                disabled={isLoading}
                aria-label={isListening ? 'Stop recording' : 'Start recording'}
                size="small"
                color={isListening ? 'primary' : undefined}
                sx={{
                  transition: theme => theme.transitions.create(['color', 'transform'], {
                    duration: theme.transitions.duration.shorter,
                  }),
                }}
              >
                <FontAwesomeIcon 
                  icon={isListening ? 'stop' : 'microphone'} 
                  style={{
                    transform: isListening ? 'scale(0.85)' : 'none',
                  }}
                />
              </StyledIconButton>
            )}
            <StyledIconButton
              type="button"
              onClick={isLoading ? handleStopClick : handleSubmit}
              disabled={isListening}
              aria-label={isLoading ? 'Stop generating' : 'Send message'}
              size="small"
              color={isLoading ? 'primary' : undefined}
            >
              <FontAwesomeIcon 
                icon={isLoading ? 'stop' : 'paper-plane'} 
                style={{
                  transform: isLoading ? 'scale(0.85)' : 'none',
                }}
              />
            </StyledIconButton>
          </ButtonContainer>
        </InputWrapper>
      </InputContainer>
    </form>
  );
}; 