import React from 'react';
import { Box, Paper, IconButton, CircularProgress, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import useSpeech from '../../../shared/hooks/useSpeech';
import type { Message } from '../../../shared/types';

export interface MessageListProps {
  messages: Message[];
  error?: string | null;
  inputHeight?: number;
  onScrollStateChange?: (isScrolledUp: boolean) => void;
  onScrollToBottomRef?: React.MutableRefObject<(() => void) | undefined>;
}

interface MessagesContainerProps {
  inputHeight?: number;
}

const MessagesContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'inputHeight',
})<MessagesContainerProps>(({ theme, inputHeight = 100 }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  paddingBottom: `${inputHeight}px`,
  height: '100%',
}));

const MessageHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
  minHeight: 24,
  marginLeft: theme.spacing(2),
}));

const RoleLabel = styled(Box)<{ textColor?: string }>(({ theme, textColor }) => ({
  fontSize: '0.75rem',
  color: textColor || theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightBold,
}));

const MessageActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  width: 24,
  height: 24,
  color: theme.palette.text.secondary,
  position: 'relative',
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
  },
  '&.Mui-active': {
    color: theme.palette.action.active,
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(1)',
        opacity: 1,
      },
      '50%': {
        transform: 'scale(1.1)',
        opacity: 0.9,
      },
      '100%': {
        transform: 'scale(1)',
        opacity: 1,
      },
    },
    animation: 'pulse 2s infinite ease-in-out',
  },
  '&.Mui-disabled': {
    color: theme.palette.action.disabled,
  },
  '& svg': {
    fontSize: '14px',
    transition: theme.transitions.create(['transform', 'color'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  marginLeft: -8,
  marginTop: -8,
}));

const MessageBubble = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isUser' && prop !== 'isError',
})<{ isUser: boolean; isError?: boolean }>(({ theme, isUser, isError }) => ({
  maxWidth: '80%',
  margin: theme.spacing(0, 2),
  padding: theme.spacing(0.5, isUser ? 3 : 0),
  paddingLeft: isUser ? theme.spacing(3) : theme.spacing(6),
  borderRadius: theme.shape.borderRadius.bubble,
  position: 'relative',
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  textAlign: isUser ? 'right' : 'left',
  background: isError 
    ? theme.palette.error.dark
    : isUser 
      ? theme.palette.gradient.primary
      : 'transparent',
  color: (isError || isUser)
    ? theme.palette.common.white
    : theme.palette.text.primary,
  border: 'none',
  overflow: 'visible',
  boxShadow: (isError || isUser) ? theme.shadows[2] : 'none',
  '&:hover': {
    boxShadow: (isError || isUser) ? theme.shadows[4] : 'none'
  },
  
  '& pre': {
    background: theme.palette.background.dark,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius.small,
    overflowX: 'auto',
    '& code': {
      color: theme.palette.text.primary,
    }
  },
  '& table': {
    borderCollapse: 'collapse',
    margin: theme.spacing(2, 0),
    '& th, & td': {
      border: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1),
      textAlign: 'left',
      minWidth: '100px',
      whiteSpace: 'nowrap',
    },
    '& th': {
      background: theme.palette.background.dark,
    }
  },
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius.small,
  }
}));

const MessageRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  margin: theme.spacing(1, 0),
  paddingBottom: theme.spacing(1),
  '&[data-user="true"]': {
    flexDirection: 'row-reverse',
  },
}));

const ScrollableContent = styled('div')(({ theme }) => ({
  overflowX: 'auto',
  paddingBottom: theme.spacing(1),
  '&::-webkit-scrollbar': {
    height: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.divider,
    borderRadius: '3px',
  },
}));

const MessageContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
}));

const ImageLoadingContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius.small,
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(1),
  borderRadius: theme.shape.borderRadius.small,
  overflow: 'hidden',
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    display: 'block',
  }
}));

const ImageSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius.small,
  backgroundColor: theme.palette.background.default,
}));

const StreamingMessage: React.FC<{ 
  content: string;
  metadata?: Message['metadata'];
}> = React.memo(({ 
  content,
  metadata
}) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [imageError, setImageError] = React.useState(false);

  const getSkeletonDimensions = (size?: string) => {
    switch (size) {
      case '1024x1024':
        return { width: '100%', height: 400 };
      case '1792x1024':
        return { width: '100%', height: 300 };
      case '1024x1792':
        return { width: '100%', height: 500 };
      default:
        return { width: '100%', height: 400 };
    }
  };

  // Reset states when metadata changes (e.g., when loading a new conversation)
  React.useEffect(() => {
    setImageLoaded(false);
    setImageError(false);
  }, [metadata?.imageUrl]);

  return (
    <ScrollableContent>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {content}
      </ReactMarkdown>
      {metadata?.isGeneratingImage && (
        <ImageContainer>
          <ImageSkeleton
            variant="rectangular"
            animation="wave"
            {...getSkeletonDimensions(metadata.imageSize)}
          />
          <ImageLoadingContainer>
            <CircularProgress size={48} />
          </ImageLoadingContainer>
        </ImageContainer>
      )}
      {metadata?.imageUrl && !imageError && (
        <ImageContainer>
          {!imageLoaded && (
            <>
              <ImageSkeleton
                variant="rectangular"
                animation="wave"
                {...getSkeletonDimensions(metadata.imageSize)}
              />
              <ImageLoadingContainer>
                <CircularProgress size={48} />
              </ImageLoadingContainer>
            </>
          )}
          <img
            src={metadata.imageUrl}
            alt="Generated"
            style={{ display: imageLoaded ? 'block' : 'none' }}
            onLoad={() => setImageLoaded(true)}
            onError={() => {
              setImageError(true);
              setImageLoaded(false);
            }}
          />
        </ImageContainer>
      )}
      {imageError && (
        <Box sx={{ color: 'error.main', mt: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
          <FontAwesomeIcon icon="exclamation-circle" />
          <span>The image is no longer available. DALL-E generated images expire after some time.</span>
        </Box>
      )}
    </ScrollableContent>
  );
});

export const MessageList: React.FC<MessageListProps> = ({ 
  messages, 
  error, 
  inputHeight = 100,
  onScrollStateChange,
  onScrollToBottomRef
}) => {
  const { speak, stopSpeaking } = useSpeech(process.env.REACT_APP_OPENAI_API_KEY);
  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const [playingMessageId, setPlayingMessageId] = React.useState<number | null>(null);
  const [loadingMessageId, setLoadingMessageId] = React.useState<number | null>(null);
  const scrollableNodeRef = React.useRef<HTMLDivElement>(null);
  const [userHasScrolled, setUserHasScrolled] = React.useState(false);
  const [shouldAutoScroll, setShouldAutoScroll] = React.useState(true);

  const isNearBottom = () => {
    const scrollableNode = scrollableNodeRef.current;
    if (!scrollableNode) return true;
    
    const threshold = 100; // pixels from bottom
    const position = scrollableNode.scrollTop + scrollableNode.clientHeight;
    const height = scrollableNode.scrollHeight;
    
    return height - position <= threshold;
  };

  const isScrolledUpFar = () => {
    const scrollableNode = scrollableNodeRef.current;
    if (!scrollableNode) return false;
    
    const position = scrollableNode.scrollTop + scrollableNode.clientHeight;
    const height = scrollableNode.scrollHeight;
    
    return height - position > 400;
  };

  const scrollToBottom = (force: boolean = false) => {
    const scrollableNode = scrollableNodeRef.current;
    if (scrollableNode && (force || shouldAutoScroll)) {
      scrollableNode.scrollTo({
        top: scrollableNode.scrollHeight,
        behavior: force ? 'auto' : 'smooth'
      });
    }
  };

  const handleScroll = React.useCallback(() => {
    const scrollableNode = scrollableNodeRef.current;
    if (!scrollableNode) return;

    const farFromBottom = isScrolledUpFar();
    onScrollStateChange?.(farFromBottom);

    // Only update auto-scroll if user has manually scrolled
    if (userHasScrolled) {
      setShouldAutoScroll(isNearBottom());
    }
  }, [onScrollStateChange, userHasScrolled]);

  // Handle user scroll events
  const handleUserScroll = React.useCallback(() => {
    if (!userHasScrolled) {
      setUserHasScrolled(true);
    }
    handleScroll();
  }, [handleScroll, userHasScrolled]);

  React.useEffect(() => {
    const scrollableNode = scrollableNodeRef.current;
    if (scrollableNode) {
      scrollableNode.addEventListener('scroll', handleUserScroll);
      return () => scrollableNode.removeEventListener('scroll', handleUserScroll);
    }
  }, [handleUserScroll]);

  // Auto-scroll on new messages if enabled
  React.useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage) {
      // Force scroll on user messages
      if (lastMessage.role === 'user') {
        scrollToBottom(true);
        setShouldAutoScroll(true);
      } 
      // Smooth scroll on AI messages if auto-scroll is enabled
      else if (shouldAutoScroll) {
        scrollToBottom(false);
      }
    }
  }, [messages, shouldAutoScroll]);

  // Reset scroll state on new conversation
  React.useEffect(() => {
    if (messages.length === 0) {
      setUserHasScrolled(false);
      setShouldAutoScroll(true);
    }
  }, [messages.length]);

  // Expose scrollToBottom through ref
  React.useEffect(() => {
    if (onScrollToBottomRef) {
      onScrollToBottomRef.current = () => {
        scrollToBottom(true);
        setShouldAutoScroll(true);
      };
    }
  }, [onScrollToBottomRef]);

  const handleSpeak = async (content: string, messageId: number) => {
    if (playingMessageId === messageId) {
      stopSpeaking();
      setPlayingMessageId(null);
      setLoadingMessageId(null);
    } else {
      if (playingMessageId !== null) {
        stopSpeaking();
      }
      setLoadingMessageId(messageId);
      setPlayingMessageId(null);
      
      try {
        await speak(content, () => {
          setPlayingMessageId(null);
          setLoadingMessageId(null);
        });
        setPlayingMessageId(messageId);
      } catch (error) {
        setPlayingMessageId(null);
      } finally {
        setLoadingMessageId(null);
      }
    }
  };

  return (
    <Box sx={{ position: 'relative', height: '100%', flex: 1 }}>
      <SimpleBar scrollableNodeProps={{ ref: scrollableNodeRef }} style={{ height: '100%', flex: 1 }}>
        <MessagesContainer inputHeight={inputHeight}>
          {messages.map((message, index) => (
            <MessageRow
              key={index}
              data-user={message.role === 'user'}
            >
              <MessageContentWrapper>
                {message.role === 'assistant' && message.metadata?.customRole && (
                  <MessageHeader>
                    <RoleLabel textColor={message.metadata?.color}>
                      {message.metadata.customRole}
                    </RoleLabel>
                    {!message.metadata?.isError && (
                      <MessageActions>
                        <ActionButton
                          onClick={() => handleSpeak(message.content, index)}
                          className={playingMessageId === index ? 'Mui-active' : ''}
                          disabled={loadingMessageId !== null && loadingMessageId !== index}
                          size="small"
                        >
                          {loadingMessageId === index ? (
                            <LoadingSpinner size={16} color="inherit" />
                          ) : (
                            <FontAwesomeIcon icon={playingMessageId === index ? 'stop' : 'volume-up'} />
                          )}
                        </ActionButton>
                      </MessageActions>
                    )}
                  </MessageHeader>
                )}
                <MessageBubble
                  isUser={message.role === 'user'}
                  isError={message.metadata?.isError}
                  elevation={0}
                >
                  {message.metadata?.isError ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <FontAwesomeIcon icon="exclamation-circle" />
                      <span>{message.content}</span>
                    </Box>
                  ) : (
                    <StreamingMessage content={message.content} metadata={message.metadata} />
                  )}
                </MessageBubble>
              </MessageContentWrapper>
            </MessageRow>
          ))}
          {error && (
            <MessageRow>
              <MessageBubble isUser={false} elevation={0}>
                <div style={{ color: 'red' }}>{error}</div>
              </MessageBubble>
            </MessageRow>
          )}
          <div ref={messagesEndRef} />
        </MessagesContainer>
      </SimpleBar>
    </Box>
  );
}; 