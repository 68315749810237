import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DRAWER_WIDTH } from '../../../shared/constants';

const LayoutRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
  maxWidth: '100vw',
}));

interface MainContentProps {
  open: boolean;
  isMobile: boolean;
}

const MainContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile',
})<MainContentProps>(({ theme, open, isMobile }) => ({
  flexGrow: 1,
  minWidth: 0,
  maxWidth: '100%',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && !isMobile && {
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export interface MainLayoutProps {
  sidebar?: React.ReactNode;
  children: React.ReactNode;
  sidebarOpen: boolean;
  onSidebarClose: () => void;
}

export const MainLayout: React.FC<MainLayoutProps> = ({
  sidebar,
  children,
  sidebarOpen,
  onSidebarClose,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <LayoutRoot>
      {sidebar}
      <MainContent open={sidebarOpen} isMobile={isMobile}>
        {children}
      </MainContent>
    </LayoutRoot>
  );
}; 