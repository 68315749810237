import React, { createContext, useContext, useState, useEffect } from 'react';
import type { CustomInstruction } from '../types/instructions.types';

interface SelectedInstructionContextType {
  selectedInstruction: CustomInstruction | null;
  setSelectedInstruction: (instruction: CustomInstruction | null) => void;
}

const SelectedInstructionContext = createContext<SelectedInstructionContextType | undefined>(undefined);

// Local storage key for persisting the selected instruction
const STORAGE_KEY = 'selectedInstruction';

export const SelectedInstructionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedInstruction, setSelectedInstructionState] = useState<CustomInstruction | null>(() => {
    const stored = localStorage.getItem(STORAGE_KEY);
    return stored ? JSON.parse(stored) : null;
  });

  // Persist selected instruction to localStorage
  useEffect(() => {
    if (selectedInstruction) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(selectedInstruction));
    } else {
      localStorage.removeItem(STORAGE_KEY);
    }
  }, [selectedInstruction]);

  const setSelectedInstruction = (instruction: CustomInstruction | null) => {
    setSelectedInstructionState(instruction);
  };

  return (
    <SelectedInstructionContext.Provider
      value={{
        selectedInstruction,
        setSelectedInstruction,
      }}
    >
      {children}
    </SelectedInstructionContext.Provider>
  );
};

export const useSelectedInstruction = () => {
  const context = useContext(SelectedInstructionContext);
  if (context === undefined) {
    throw new Error('useSelectedInstruction must be used within a SelectedInstructionProvider');
  }
  return context;
}; 