import React, { createContext, useContext, useState, useEffect } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { useTheme } from '@mui/material';
import { useAuth } from './AuthContext';
import {
  createInstruction,
  updateInstruction,
  deleteInstruction,
  getInstructionsQuery
} from '../services/instructionsService';
import type { CustomInstruction, CreateInstructionData } from '../types/instructions.types';

interface CustomInstructionsContextType {
  instructions: CustomInstruction[];
  loading: boolean;
  error: string | null;
  createInstruction: (data: CreateInstructionData) => Promise<void>;
  updateInstruction: (id: string, data: CreateInstructionData) => Promise<void>;
  deleteInstruction: (id: string) => Promise<void>;
  canManageInstructions: boolean;
  canEditInstruction: (instruction: CustomInstruction) => boolean;
}

const CustomInstructionsContext = createContext<CustomInstructionsContextType | undefined>(undefined);

export const CustomInstructionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useTheme();
  const [instructions, setInstructions] = useState<CustomInstruction[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user, isDevelopmentMode } = useAuth();

  // Compute whether the user can manage instructions
  const canManageInstructions = Boolean(user && isDevelopmentMode);

  // Check if a specific instruction can be edited by the current user
  const canEditInstruction = (instruction: CustomInstruction) => {
    if (!user) return false;
    if (!isDevelopmentMode) return false;
    
    // Legacy instructions (without isPublic field) are editable by anyone in dev mode
    if (instruction.isPublic === undefined || instruction.isPublic === null) return true;
    
    // Otherwise, only the creator can edit their own instructions
    return instruction.createdBy === user.uid;
  };

  useEffect(() => {
    if (!user) {
      setInstructions([]);
      setLoading(false);
      return;
    }

    const unsubscribe = onSnapshot(
      getInstructionsQuery(user.uid),
      (snapshot) => {
        const instructionsList: CustomInstruction[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          // Only include instructions that are:
          // 1. Public
          // 2. Legacy instructions (no isPublic field)
          // 3. Created by the current user
          if (
            data.isPublic === true || 
            data.isPublic === undefined || 
            data.isPublic === null || 
            data.createdBy === user.uid
          ) {
            instructionsList.push({
              id: doc.id,
              title: data.title || '',
              content: data.content || '',
              hatColor: data.hatColor || theme.palette.primary.main,
              createdAt: data.createdAt?.toDate() || new Date(),
              updatedAt: data.updatedAt?.toDate() || new Date(),
              createdBy: data.createdBy || user.uid,
              isPublic: data.isPublic ?? false // Use nullish coalescing to handle undefined/null
            });
          }
        });
        setInstructions(instructionsList);
        setLoading(false);
        setError(null);
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching instructions:', err);
        setError('Failed to load instructions');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user, theme]); // Add theme to dependencies

  const handleCreateInstruction = async (data: CreateInstructionData) => {
    if (!canManageInstructions) throw new Error('Development mode must be enabled to manage instructions');

    try {
      await createInstruction(data, user!.uid);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error creating instruction:', err);
      throw new Error('Failed to create instruction');
    }
  };

  const handleUpdateInstruction = async (id: string, data: CreateInstructionData) => {
    const instruction = instructions.find(i => i.id === id);
    if (!instruction) throw new Error('Instruction not found');
    
    if (!canEditInstruction(instruction)) {
      throw new Error('You do not have permission to edit this instruction');
    }

    try {
      await updateInstruction(id, data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error updating instruction:', err);
      throw new Error('Failed to update instruction');
    }
  };

  const handleDeleteInstruction = async (id: string) => {
    const instruction = instructions.find(i => i.id === id);
    if (!instruction) throw new Error('Instruction not found');
    
    if (!canEditInstruction(instruction)) {
      throw new Error('You do not have permission to delete this instruction');
    }

    try {
      await deleteInstruction(id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error deleting instruction:', err);
      throw new Error('Failed to delete instruction');
    }
  };

  return (
    <CustomInstructionsContext.Provider
      value={{
        instructions,
        loading,
        error,
        createInstruction: handleCreateInstruction,
        updateInstruction: handleUpdateInstruction,
        deleteInstruction: handleDeleteInstruction,
        canManageInstructions,
        canEditInstruction
      }}
    >
      {children}
    </CustomInstructionsContext.Provider>
  );
};

export const useCustomInstructions = () => {
  const context = useContext(CustomInstructionsContext);
  if (context === undefined) {
    throw new Error('useCustomInstructions must be used within a CustomInstructionsProvider');
  }
  return context;
}; 