import React, { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TOP_BAR_HEIGHT } from '../../../shared/constants';
import { MessageList } from '../MessageList';
import { ChatInput } from '../ChatInput';
import type { Message } from '../../../shared/types';

interface ChatContentProps {
  messages: Message[];
  error?: string | null;
  onSendMessage: (message: string) => void;
  onStopGeneration?: () => void;
  isLoading: boolean;
  onScrollStateChange?: (isScrolledUp: boolean) => void;
  isScrolledUp?: boolean;
  onScrollToBottom?: () => void;
}

const ContentRoot = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100dvh',
  width: '100%',
  maxWidth: '100%',
  paddingTop: TOP_BAR_HEIGHT,
  overflow: 'hidden',
}));

const MessagesWrapper = styled(Box)(() => ({
  flex: 1,
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
  maxWidth: '100%',
}));

const InputWrapper = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  maxWidth: '100%',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 2,
  background: 'transparent',
}));

export const ChatContent: React.FC<ChatContentProps> = ({
  messages,
  error,
  onSendMessage,
  onStopGeneration,
  isLoading,
  onScrollStateChange,
  isScrolledUp,
  onScrollToBottom,
}) => {
  const [inputHeight, setInputHeight] = useState(100);
  const scrollToBottomRef = React.useRef<() => void>();

  const handleScrollToBottom = () => {
    scrollToBottomRef.current?.();
    onScrollToBottom?.();
  };

  return (
    <ContentRoot>
      <MessagesWrapper>
        <MessageList 
          messages={messages} 
          error={error} 
          inputHeight={inputHeight}
          onScrollStateChange={onScrollStateChange}
          onScrollToBottomRef={scrollToBottomRef}
        />
      </MessagesWrapper>
      <InputWrapper>
        <ChatInput 
          onSendMessage={onSendMessage} 
          onStopGeneration={onStopGeneration}
          isLoading={isLoading}
          onHeightChange={setInputHeight}
          isScrolledUp={isScrolledUp}
          onScrollToBottom={handleScrollToBottom}
        />
      </InputWrapper>
    </ContentRoot>
  );
}; 